import React, { HTMLAttributes, ReactNode } from 'react';
import { css, styled } from 'styled-components';
import typo from '@components/atoms/Typo/types';
import { Typo } from '@components/atoms';
import {
  getDefaultColor,
  getDisabledColor,
  getHoveredColor,
  getPressedColor,
} from './utils';
import {
  TextButtonColorType,
  TextButtonSizeType,
  TextButtonStyleType,
} from './types';
import { ReactComponent as ChevronDownSvg } from '../../../../assets/svg/ChevronDown.svg';

interface TextButtonProps extends HTMLAttributes<HTMLButtonElement> {
  buttonColor?: TextButtonColorType;
  buttonStyle?: TextButtonStyleType;
  buttonSize?: TextButtonSizeType;
  isLeftIcon?: boolean;
  isRightIcon?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  CustomIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

function TextButton({
  buttonColor = 'primary',
  buttonStyle = 'default',
  buttonSize = 'lg',
  disabled,
  isLeftIcon,
  isRightIcon,
  CustomIcon,
  children,
  ...rest
}: TextButtonProps) {
  return (
    <TextButtonContainer
      {...rest}
      disabled={disabled}
      $buttonSize={buttonSize}
      $buttonColor={buttonColor}
      $buttonStyle={buttonStyle}
    >
      {isLeftIcon && (
        <ChevronDownIcon style={{ marginRight: 2 }} $buttonSize={buttonSize} />
      )}
      <TextButtonText $buttonSize={buttonSize}>
        {children ?? 'text'}
      </TextButtonText>
      {isRightIcon && (
        <ChevronDownIcon style={{ marginLeft: 2 }} $buttonSize={buttonSize} />
      )}
    </TextButtonContainer>
  );
}

export default TextButton;

const TextButtonContainer = styled.button<{
  $buttonColor?: TextButtonColorType;
  $buttonStyle?: TextButtonStyleType;
  $buttonSize?: TextButtonSizeType;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ $buttonColor, $buttonStyle }) =>
    getDefaultColor($buttonColor, $buttonStyle)};
  svg > path,
  span {
    ${({ $buttonColor, $buttonStyle }) =>
      getDefaultColor($buttonColor, $buttonStyle)};
  }
  &:focus-visible,
  &:hover {
    ${({ $buttonColor, $buttonStyle }) =>
      getHoveredColor($buttonColor, $buttonStyle)};
    svg > path,
    span {
      ${({ $buttonColor, $buttonStyle }) =>
        getHoveredColor($buttonColor, $buttonStyle)};
    }
  }
  &:active {
    ${({ $buttonColor, $buttonStyle }) =>
      getPressedColor($buttonColor, $buttonStyle)};
    svg > path,
    span {
      ${({ $buttonColor, $buttonStyle }) =>
        getPressedColor($buttonColor, $buttonStyle)};
    }
  }
  &:disabled {
    ${({ $buttonStyle }) => getDisabledColor($buttonStyle)};
    svg > path,
    span {
      ${({ $buttonStyle }) => getDisabledColor($buttonStyle)};
    }
  }
`;

const TextButtonText = styled(Typo)<{
  $buttonSize?: TextButtonSizeType;
}>`
  ${({ $buttonSize }) =>
    $buttonSize === 'lg'
      ? css`
          ${typo.btn2}
        `
      : `${typo.btn3} `};
`;

const ChevronDownIcon = styled(ChevronDownSvg)<{
  $buttonSize?: TextButtonSizeType;
}>`
  ${({ $buttonSize }) => {
    if ($buttonSize === 'lg')
      return css`
        width: 18px;
        height: 18px;
      `;
    return css`
      width: 16px;
      height: 16px;
    `;
  }}
`;
