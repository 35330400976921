import React, { useCallback, useEffect, useState } from 'react';
import { ContentLayout } from '@components/molecules';
import DatePicker from '@components/v2/antd/data-entry/DatePicker';
import Select from '@components/v2/antd/data-entry/Select';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { List, Tag } from 'antd';
import Button from '@components/v2/antd/general/Button';
import { ReloadOutlined } from '@ant-design/icons';
import { fetchCrmReservations } from '../../../../@apis/crm-reservations';
import { fetchPartners } from '../../../../@apis/partners';
import {
  CrmReservation,
  LocalReservation,
  VegasReservation,
} from '../../../../@types/crm-reservation/models/CrmReservation';

function CrmReservationListPage() {
  const [date, setDate] = useState(dayjs());
  const [partnerId, setPartnerId] = useState(
    process.env.REACT_APP_ENV === 'prod' ? 24 : 4,
  );
  const [partnerOptions, setPartnerOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [totalCrmReservationCount, setTotalCrmReservationCount] = useState(0);
  const [crmReservations, setCrmReservations] = useState<CrmReservation[]>([]);

  const fetchPartnerOptions = useCallback(async () => {
    try {
      const { rows } = await fetchPartners({
        page: 1,
        pageSize: 9999,
      });
      setPartnerOptions(
        rows.map(({ partnerName, displayName, id }) => ({
          label: displayName || partnerName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetchData() {
      await fetchPartnerOptions();
    })();
  }, [fetchPartnerOptions]);

  const { isFetching, refetch } = useQuery({
    queryKey: `FETCH_CRM_RESERVATIONS_${date.format(
      'YYYY-MM-DD',
    )}_${partnerId}`,
    queryFn: async () => {
      return fetchCrmReservations(partnerId, {
        targetDate: date.format('YYYY-MM-DD'),
      });
    },
    onSuccess: (data) => {
      const { rows, count } = data;
      setCrmReservations(rows);
      setTotalCrmReservationCount(count);
    },
    onError: () => {
      setCrmReservations([]);
      setTotalCrmReservationCount(0);
    },
    retry: false,
    enabled: !!date && !!partnerId,
    refetchOnWindowFocus: false,
  });

  const formatDate = (dateString?: string) => {
    if (dateString) {
      return dayjs(dateString).format('YYYY-MM-DD HH:mm');
    }
    return '-';
  };

  const getLocalReservationStatus = (reservation: LocalReservation): string => {
    let status = '예약대기';
    if (reservation.canceledAt) {
      status = '취소';
    } else if (reservation.finishedAt) {
      status = '응대완료';
    } else if (reservation.calculatedAt) {
      status = '수납완료';
    } else if (reservation.chartedAt) {
      status = '차팅완료';
    } else if (reservation.receiptedAt) {
      status = '접수';
    } else if (reservation.reserveConfirmedAt) {
      status = '확정';
    } else if (!reservation.reserveConfirmedAt) {
      status = '대기';
    }
    return status;
  };

  const getIssuedReservationCount = () => {
    return crmReservations.filter(({ localReservation, crmReservation }) => {
      return (
        (localReservation && !crmReservation) ||
        (!localReservation && crmReservation)
      );
    }).length;
  };

  const getHealthyReservationCount = () => {
    return crmReservations.filter(({ localReservation, crmReservation }) => {
      return localReservation && crmReservation;
    }).length;
  };

  const renderCrmReservation = (crmReservation: VegasReservation) => {
    const { reservedAt, resvStatusName, reservationChannelName, sid, cid } =
      crmReservation;
    return (
      <div className="flex flex-col w-full gap-1">
        <div className="flex items-center gap-2">
          <span>예약채널</span>
          <Tag>{reservationChannelName}</Tag>
        </div>
        <div className="flex items-center gap-2">
          <span>예약상태</span>
          <Tag>{resvStatusName}</Tag>
        </div>
        <div className="flex items-center gap-2">
          <span>예약일</span>
          <span>{formatDate(reservedAt)}</span>
        </div>
        <div className="flex items-center gap-2">
          <span>고객아이디</span>
          <span>{cid}</span>
        </div>
        <div className="flex items-center gap-2">
          <span>예약아이디</span>
          <span>{sid}</span>
        </div>
      </div>
    );
  };

  const renderLocalReservation = (localReservation: LocalReservation) => {
    const {
      reservedAt,
      receiptedAt,
      reservationCrmNo,
      reservationChannel,
      partnerCustomer,
    } = localReservation;

    let customerName = '';
    if (partnerCustomer) {
      customerName = partnerCustomer.name;
    }

    return (
      <div className="flex flex-col w-full gap-1">
        <div className="flex items-center gap-2">
          <span>예약채널</span>
          <Tag>{reservationChannel}</Tag>
        </div>
        <div className="flex items-center gap-2">
          <span>예약상태</span>
          <Tag>{getLocalReservationStatus(localReservation)}</Tag>
        </div>
        <div className="flex items-center gap-2">
          <span>예약자명</span>
          <span>{customerName}</span>
        </div>
        <div className="flex items-center gap-2">
          <span>예약일</span>
          <span>{formatDate(reservedAt)}</span>
        </div>

        <div className="flex items-center gap-2">
          <span>접수일</span>
          <span>{formatDate(receiptedAt)}</span>
        </div>
        <div className="flex items-center gap-2">
          <span>예약아이디</span>
          <span>{reservationCrmNo}</span>
        </div>
      </div>
    );
  };

  return (
    <ContentLayout
      style={{
        flex: 1,
      }}
      contentStyle={{
        flex: 1,
      }}
    >
      <div
        className="flex flex-col gap-3"
        style={{
          height: '75vh',
        }}
      >
        <div className="flex items-center gap-2">
          <DatePicker
            allowClear={false}
            value={date}
            onChange={(date) => {
              setDate(date);
            }}
          />
          <Select
            value={partnerId}
            options={partnerOptions}
            placeholder="파트너 선택"
            style={{
              minWidth: '240px',
            }}
            onChange={(value) => {
              setPartnerId(value);
            }}
          />
          <Button
            type="default"
            icon={<ReloadOutlined />}
            onClick={() => {
              refetch();
            }}
          />
          <div className="flex-1" />
          <div
            style={{
              fontSize: 14,
            }}
          >
            총 예약 :{' '}
            <span className="font-bold">{totalCrmReservationCount}</span>
          </div>
          <div
            style={{
              fontSize: 14,
              color: '#2dcc51',
            }}
          >
            정상 :{' '}
            <span className="font-bold">{getHealthyReservationCount()}</span>
          </div>
          <div
            style={{
              fontSize: 14,
              color: '#e17171',
            }}
          >
            <span>이슈 : </span>
            <span className="font-bold">{getIssuedReservationCount()}</span>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto bg-white">
          <List
            loading={isFetching}
            dataSource={crmReservations}
            header={
              <div className="flex items-center px-[20px]">
                <div className="flex-1 font-bold">CRM</div>
                <div className="flex-1 font-bold">BOOSTREE</div>
              </div>
            }
            renderItem={(item) => {
              const { crmReservation, localReservation } = item;
              const warn =
                (!crmReservation && localReservation) ||
                (crmReservation && !localReservation);
              return (
                <List.Item
                  style={{
                    background: warn ? '#e17171' : 'white',
                  }}
                >
                  <div className="flex items-center px-[20px]  w-full">
                    <div className="flex-1">
                      {crmReservation ? (
                        renderCrmReservation(crmReservation)
                      ) : (
                        <div>CRM 예약 없음</div>
                      )}
                    </div>
                    <div className="flex-1">
                      {localReservation ? (
                        renderLocalReservation(localReservation)
                      ) : (
                        <div>부스트리 예약 없음</div>
                      )}
                    </div>
                  </div>
                </List.Item>
              );
            }}
          />
        </div>
      </div>
    </ContentLayout>
  );
}

export default CrmReservationListPage;
