import React, { useCallback } from 'react';
import PaginationTablePage from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { noticeListCols } from '@pages/private/customer/partner-notices/datas';
import { fetchPartnerNotices } from '../../../../@apis/partner-notices';
import { FetchPartnerNoticeListQuery } from '../../../../@types/partner-notice/params/FetchPartnerNoticeListQuery';
import { PartnerNotice } from '../../../../@types/partner-notice/models/PartnerNotice';

function NoticeListPage() {
  const navigate = useNavigate();

  const dataFetcher = useCallback(
    async (query: FetchPartnerNoticeListQuery) => {
      const { rows, count } = await fetchPartnerNotices({
        ...query,
      });
      return {
        rows,
        count,
      };
    },
    [],
  );

  const handleTableRowClick = (data: PartnerNotice) => {
    const { id } = data;
    navigate(PAGE_PATHS.CUSTOMER_NOTICE_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.CUSTOMER_NOTICE_ADD}>
          <Button>공지사항 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={noticeListCols()}
      onRowClick={(row) => {
        handleTableRowClick(row);
      }}
    />
  );
}

export default NoticeListPage;
