import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { fetchGlobalPrompts } from '@apis/global-prompts';
import { FetchGlobalPromptListQuery } from '../../../../@types/global-prompts/params/FetchGlobalPromptListQuery';
import { GlobalPrompt } from '../../../../@types/global-prompts/models/GlobalPrompt';
import { useGlobalPromptRoleOptions } from './queries';

function GlobalPromptListPage() {
  const navigate = useNavigate();

  const { data: promptRoleOptions = [] } = useGlobalPromptRoleOptions();

  const COLS: ColDef[] = [
    {
      field: 'roleName',
      minWidth: 200,
      width: 200,
      headerName: '프롬프트 유형',
      sortable: false,
    },
    {
      field: 'message',
      flex: 1,
      headerName: '메시지',
      sortable: false,
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(async (query: FetchGlobalPromptListQuery) => {
    const { rows, count } = await fetchGlobalPrompts(query);
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: GlobalPrompt) => {
    const { id } = data;
    navigate(PAGE_PATHS.AI_DATA_GLOBAL_PROMPT_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.AI_DATA_GLOBAL_PROMPT_ADD}>
          <Button>프롬프트 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'role',
      options: promptRoleOptions,
      label: '프롬프트 유형',
      placeholder: '프롬프트 유형 선택',
    },
  ];

  return (
    <PaginationTablePage
      filterForms={filterForms}
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      onRowClick={handleTableRowClick}
    />
  );
}

export default GlobalPromptListPage;
