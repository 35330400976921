import React, { useCallback } from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { FetchAuthorityGroupListQuery } from '../../../../@types/authority-group/params/FetchAuthorityGroupListQuery';
import { fetchAuthorityGroups } from '../../../../@apis/authority-groups';
import { AuthorityGroup } from '../../../../@types/authority-group/models/AuthorityGroup';

function AuthorityGroupListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    authorityName: '',
  };

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchAuthorityGroupListQuery> =
    {
      filterKey: 'authorityName',
      searchInputPlaceholder: '권한그룹명 검색',
    };

  const COLS: ColDef[] = [
    {
      field: 'authorityName',
      minWidth: 153,
      width: 153,
      flex: 1,
      headerName: '권한그룹명',
      sortable: false,
    },
    {
      field: 'version',
      minWidth: 153,
      width: 153,
      headerName: '버전',
      sortable: false,
    },
    {
      field: 'createdAt',
      minWidth: 153,
      width: 153,
      headerName: '등록일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
    {
      field: 'updatedAt',
      minWidth: 153,
      width: 153,
      headerName: '마지막 수정일',
      cellRenderer: ({ value }: ICellRendererParams) => {
        return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
      },
      sortable: false,
    },
  ];

  const dataFetcher = useCallback(
    async (query: FetchAuthorityGroupListQuery) => {
      const { rows, count } = await fetchAuthorityGroups(query);
      return {
        rows,
        count,
      };
    },
    [],
  );

  const handleTableRowClick = (data: AuthorityGroup) => {
    const { id } = data;
    navigate(
      PAGE_PATHS.SETTINGS_AUTHORITY_GROUPS_DETAIL.replace(':id', `${id}`),
    );
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.SETTINGS_AUTHORITY_GROUP_ADD}>
          <Button>권한 그룹 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
    />
  );
}

export default AuthorityGroupListPage;
