import { AppError, request, Row, Rows } from '@networks/index';
import { MemberAuthority } from '../@types';
import { Member } from '../@types/member/models/Member';
import { FetchMemberAccountListQuery } from '../@types/member/params/FetchMemberAccountListQuery';
import { CreateMemberAccountBody } from '../@types/member/params/CreateMemberAccountBody';
import { UpdateMemberAccountBody } from '../@types/member/params/UpdateMemberAccountBody';

export const deleteOvMember = async (partnerGroupPartnerMemberId: number) => {
  const url = `/admin/v1/partner-members/${partnerGroupPartnerMemberId}`;
  try {
    const { data } = await request<Rows<MemberAuthority>>({
      method: 'delete',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
export const fetchSystemAuthorities = async (partnerId?: number) => {
  const url = `/admin/v1/members/system-authorities`;
  try {
    const { data } = await request<Rows<MemberAuthority>>({
      method: 'get',
      url,
      queryParams: { partnerId },
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const addOvMember = async (body: {
  memberId: number;
  partnerId: number;
  memberAuthorityId: number;
}) => {
  const url = `/admin/v1/partner-members`;
  try {
    const { data } = await request({
      method: 'post',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const addMemberAccount = async (body: CreateMemberAccountBody) => {
  const url = `/admin/v1/partner-members/accounts`;
  try {
    const { data } = await request({
      method: 'post',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateMemberAccount = async (
  memberId: number | string,
  body: UpdateMemberAccountBody,
) => {
  const url = `/admin/v1/partner-members/accounts/${memberId}`;
  try {
    const { data } = await request({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateMemberAccountPassword = async (
  memberId: number | string | undefined,
) => {
  const url = `/admin/v1/partner-members/accounts/${memberId}/password`;
  try {
    const { data } = await request({
      method: 'put',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchOvMembers = async () => {
  const url = `/admin/v1/members`;
  try {
    const { data } = await request<Rows<Member>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchMemberAccounts = async (
  query: FetchMemberAccountListQuery,
) => {
  const url = `/admin/v1/partner-members/accounts`;
  try {
    const { data } = await request<Rows<Member>>({
      method: 'get',
      url,
      queryParams: query,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchMemberAccountDetail = async (memberId: number | string) => {
  const url = `/admin/v1/partner-members/accounts/${memberId}`;
  try {
    const { data } = await request<Row<Member>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
