import React, { useCallback, useState } from 'react';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import { Button } from '@components/atoms';
import copy from 'copy-to-clipboard';
import { billingListCols } from '@pages/private/customer/billings/datas';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { SetBillingConfigModal } from './components/SetBillingConfigModal';
import { fetchBillings } from '../../../../@apis/billings';
import { FetchBillingListQuery } from '../../../../@types/billing/params/FetchBillingListQuery';
import { Billing } from '../../../../@types/billing/models/Billing';
import { IS_PAID_OPTIONS } from '../../../../@types/billing/constants/billing.contstants';

function BillingListPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { showToast } = useToastStore();
  const [setBillingConfigModalPartnerId, setSetBillingConfigModalPartnerId] =
    useState<number | null>(null);

  const FILTER_INITIAL_VALUES = {
    partnerName: '',
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'isPaid',
      options: IS_PAID_OPTIONS,
      label: '결제 상태',
      placeholder: '결제 상태 선택',
    },
  ];

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchBillingListQuery> =
    {
      filterKey: 'partnerName',
      searchInputPlaceholder: '파트너명 검색',
    };

  const dataFetcher = useCallback(async (query: FetchBillingListQuery) => {
    const { rows, count } = await fetchBillings({
      ...query,
    });

    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: Billing) => {
    const { id } = data;
    navigate(PAGE_PATHS.CUSTOMER_BILLING_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.CUSTOMER_BILLING_ADD}>
          <Button>청구서 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  const handleSetBillingConfig = (data: Billing) => {
    const { partner } = data;
    if (partner?.id) {
      setSetBillingConfigModalPartnerId(partner?.id);
    }
  };

  const handleCopyUrl = (url: string) => {
    copy(url);
    showToast({
      description: '클립보드에 결제 URL이 복사되었어요',
      status: 'Primary',
    });
  };

  const onBillingConfigModalClose = useCallback(
    (isRefresh?: boolean) => {
      setSetBillingConfigModalPartnerId(null);
      if (isRefresh) {
        navigate(pathname);
      }
    },
    [pathname, navigate],
  );

  return (
    <>
      <PaginationTablePage
        headerActions={renderContentRightButton()}
        dataFetcher={dataFetcher}
        columns={billingListCols({
          handleSetBillingConfig,
          handleCopyUrl,
        })}
        filterInitialValues={FILTER_INITIAL_VALUES}
        onRowClick={(row, column: any) => {
          if (column?.colDef?.field === 'billingConfig') {
            if (
              row?.partner?.billingConfig &&
              (row.partner.billingConfig.buyerName ||
                row.partner.billingConfig.buyerEmail ||
                row.partner.billingConfig.buyerTel)
            ) {
              handleTableRowClick(row);
            }
          } else if (column?.colDef?.field !== 'url') {
            handleTableRowClick(row);
          }
        }}
        keywordFilterInfo={keywordFilterInfo}
        filterForms={filterForms}
      />
      {setBillingConfigModalPartnerId && (
        <SetBillingConfigModal
          partnerId={setBillingConfigModalPartnerId}
          onClose={onBillingConfigModalClose}
        />
      )}
    </>
  );
}

export default BillingListPage;
