import React, { useCallback } from 'react';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { Link, useNavigate } from 'react-router-dom';
import { ColDef } from 'ag-grid-community';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { fetchPricingPlan } from '@apis/pricing-plan';
import { PricingPlan } from '../../../../@types/pricing-plan/models/PricingPlan';
import { FetchPricingPlanListQuery } from '../../../../@types/pricing-plan/params/FetchPricingPlanListQuery';

function PricingPlanListPage() {
  const navigate = useNavigate();

  const FILTER_INITIAL_VALUES = {
    title: '',
  };

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchPricingPlanListQuery> =
    {
      filterKey: 'title',
      searchInputPlaceholder: '제목 검색',
    };

  const COLS: ColDef[] = [
    {
      field: 'title',
      width: 500,
      headerName: '제목',
      sortable: false,
      cellRenderer: (col: any) => {
        const { data } = col;
        return data.title;
      },
    },
    {
      field: 'createdAt',
      width: 200,
      headerName: '등록일',
      sortable: false,
      cellRenderer: (col: any) => {
        const { data } = col;
        return dayjs(data.createdAt).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      field: 'updatedAt',
      width: 200,
      headerName: '수정일',
      sortable: false,
      cellRenderer: (col: any) => {
        const { data } = col;
        return dayjs(data.updatedAt).format('YYYY-MM-DD HH:mm');
      },
    },
  ];

  const dataFetcher = useCallback(async () => {
    const { rows, count } = await fetchPricingPlan();
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: PricingPlan) => {
    const { id } = data;
    navigate(PAGE_PATHS.SETTING_PRICING_PLAN_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.SETTING_PRICING_PLAN_ADD}>
          <Button>메뉴 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  return (
    <PaginationTablePage
      headerActions={renderContentRightButton()}
      dataFetcher={dataFetcher}
      columns={COLS}
      filterInitialValues={FILTER_INITIAL_VALUES}
      onRowClick={handleTableRowClick}
      keywordFilterInfo={keywordFilterInfo}
    />
  );
}

export default PricingPlanListPage;
