import React, {
  ForwardedRef,
  KeyboardEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ConfigProvider,
  Select as AntdSelect,
  SelectProps as AntdSelectProps,
} from 'antd';
import { styled } from 'styled-components';
import colorSet from '@styles/colors';
import { ReactComponent as Close } from '@assets/svg/Close.svg';
import { isArray } from 'lodash';
import { DefaultOptionType } from 'antd/es/select';
import { BaseSelectRef } from 'rc-select';
import { ArrowIcon } from './styles';
// import SelectOptions from './components/SelectOptions';
import typo from '../Typo/types';
import Typo from '../Typo';

export enum CustomMode {
  MULTI_TAG = 'MULTI_TAG',
  SINGLE_TAG = 'SINGLE_TAG',
  DATA_TAG = 'DATA_TAG',
}
export interface SelectProps extends AntdSelectProps {
  isMultiSelect?: boolean;
  customeMode?: CustomMode;
  hasSelect?: boolean;
  externalItemElement?: React.ReactNode;
  onExternalItemClick?: () => void;
}

const Select = forwardRef(
  ({ ...props }: SelectProps, ref: ForwardedRef<BaseSelectRef>) => {
    const {
      disabled,
      options,
      value,
      onChange,
      customeMode,
      hasSelect,
      externalItemElement,
      onExternalItemClick,
      showSearch,
    } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchText, setSearchText] = useState<string>('');
    const [open, setOpen] = useState(false);

    const handleDropdownClose = () => {
      setOpen(false);
    };

    const [selectIdx, setSelectIdx] = useState(0);
    const selectRef = useRef<HTMLUListElement>(null);

    const searchValue =
      searchText !== '' &&
      (customeMode === CustomMode.SINGLE_TAG ||
        customeMode === CustomMode.MULTI_TAG)
        ? [
            {
              label: searchText,
              value: searchText,
            },
          ]
        : [];

    let valueOptions: DefaultOptionType[] = [
      ...searchValue,
      ...(options || []),
    ];

    if (customeMode) {
      if (customeMode === CustomMode.DATA_TAG) {
        valueOptions = [
          ...valueOptions.filter((item) =>
            (item.label as unknown as string)?.includes(searchText),
          ),
        ];
      } else {
        if (customeMode !== CustomMode.SINGLE_TAG) {
          for (let index = 0; index < (value || []).length; index += 1) {
            const el = value[index];
            if (!valueOptions?.find((item) => item.value === el)) {
              valueOptions.push({
                label: el,
                value: el,
              });
            }
          }
        }

        valueOptions = [
          ...searchValue,
          ...valueOptions
            .filter((item) => item.label !== searchText)
            .filter((item) =>
              (item.label as unknown as string)?.includes(searchText),
            ),
        ];
      }
    }
    if (hasSelect) {
      valueOptions.unshift({
        label: '선택안함',
        value: null,
      });
    }

    const handleKeydownAction = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
        if (open) {
          if (valueOptions?.[selectIdx]) {
            (onChange as any)(valueOptions?.[selectIdx].value);

            if (customeMode) {
              if (customeMode !== CustomMode.SINGLE_TAG) {
                setSearchText('');
              } else {
                setSearchText(String(valueOptions?.[selectIdx].label));
              }

              if (customeMode === CustomMode.SINGLE_TAG) {
                setSelectIdx(0);
                selectRef?.current?.scrollTo(0, 0);
              } else if (
                !options?.find(
                  (item) => item.value === valueOptions?.[selectIdx].value,
                )
              ) {
                setSelectIdx(0);
                selectRef?.current?.scrollTo(0, 0);
              }
            }
          } else {
            setSearchText('');
          }
          if (!customeMode) handleDropdownClose();
          if (customeMode === CustomMode.SINGLE_TAG) handleDropdownClose();
          if (
            externalItemElement &&
            onExternalItemClick &&
            selectIdx === options?.length
          ) {
            onExternalItemClick();
          }
        }
      }
      if (e.key === 'ArrowDown') {
        const select = selectRef?.current?.children[selectIdx + 1];
        if (
          (selectRef?.current?.scrollTop || 0) +
            (selectRef.current?.clientHeight || 0) <=
          (select?.clientHeight || 0) * (selectIdx + 1.5)
        ) {
          selectRef?.current?.scrollTo(
            0,
            (select?.clientHeight || 0) * (selectIdx + 2) -
              selectRef.current.clientHeight,
          );
        }

        if (externalItemElement) {
          if ((valueOptions?.length || 0) > selectIdx) {
            setSelectIdx((prev) => prev + 1);
          } else {
            setSelectIdx(0);
            selectRef?.current?.scrollTo(0, 0);
          }
        } else if ((valueOptions?.length || 0) - 1 > selectIdx) {
          setSelectIdx((prev) => prev + 1);
        } else {
          setSelectIdx(0);
          selectRef?.current?.scrollTo(0, 0);
        }
      }
      if (e.key === 'ArrowUp') {
        const select = selectRef?.current?.children[selectIdx];

        if (
          (selectRef?.current?.scrollTop || 0) >=
          (select?.clientHeight || 0) * (selectIdx - 0.5)
        ) {
          selectRef?.current?.scrollTo(
            0,
            (select?.clientHeight || 0) * selectIdx -
              (select?.clientHeight || 0),
          );
        }

        if (externalItemElement) {
          if (selectIdx > 0) {
            setSelectIdx((prev) => prev - 1);
          } else {
            setSelectIdx(valueOptions?.length || 0 || 0);
            selectRef?.current?.scrollTo(0, selectRef?.current.scrollHeight);
          }
        } else if (selectIdx > 0) {
          setSelectIdx((prev) => prev - 1);
        } else {
          setSelectIdx((valueOptions?.length || 0) - 1 || 0);
          selectRef?.current?.scrollTo(0, selectRef?.current.scrollHeight);
        }
      }
    };

    const renderDropdownMenu = () => {
      if (customeMode) {
        return (
          <SelectOptionsContainer
            ref={selectRef}
            // maxWidth={menuMaxWidth}
            // maxHeight={menuMaxHeight}
            // onMouseDown={(e) => onMouseDown?.(e)}
          >
            {valueOptions?.map((item, idx) => (
              <SelectItem
                key={idx.toString()}
                aria-disabled={disabled}
                onClick={() => {
                  // e.stopPropagation();
                  (onChange as any)(item.value);
                  if (customeMode === CustomMode.SINGLE_TAG)
                    handleDropdownClose();
                  if (customeMode !== CustomMode.SINGLE_TAG) {
                    setSearchText('');
                  } else {
                    setSearchText(String(item.label));
                  }
                }}
                onMouseOver={() => {
                  setSelectIdx(idx);
                }}
                data-checked={
                  customeMode === CustomMode.SINGLE_TAG
                    ? value === item.value
                    : value.includes(item.value)
                }
                data-focused={idx === selectIdx}
              >
                <Typo as="p" typoType="b9">
                  {item.label}
                </Typo>
                <span className="icon" />
              </SelectItem>
            ))}
          </SelectOptionsContainer>
        );
      }
      return (
        <SelectOptionsContainer
          ref={selectRef}
          // maxWidth={menuMaxWidth}
          // maxHeight={menuMaxHeight}
          // onMouseDown={(e) => onMouseDown?.(e)}
        >
          {valueOptions?.map((item, idx) => (
            <SelectItem
              key={idx.toString()}
              onClick={() => {
                // e.stopPropagation();
                (onChange as any)(item.value);
                handleDropdownClose();
                if (customeMode) {
                  if (customeMode !== CustomMode.SINGLE_TAG) {
                    setSearchText('');
                  } else {
                    setSearchText(String(item.label));
                  }
                }
              }}
              onMouseOver={() => {
                setSelectIdx(idx);
              }}
              data-checked={String(value) === String(item.value)}
              data-focused={idx === selectIdx}
            >
              <Typo as="p" typoType="b9">
                {item.label}
              </Typo>
              <span className="icon" />
            </SelectItem>
          ))}
          {externalItemElement && (
            <SelectItem
              aria-disabled={disabled}
              onClick={() => {
                handleDropdownClose();
                onExternalItemClick?.();
              }}
              onMouseOver={() => {
                setSelectIdx(valueOptions.length);
              }}
              data-focused={valueOptions.length === selectIdx}
            >
              {externalItemElement}
            </SelectItem>
          )}
        </SelectOptionsContainer>
      );
    };

    useEffect(() => {
      window.addEventListener('click', handleDropdownClose);
      return () => {
        window.removeEventListener('click', handleDropdownClose);
      };
    }, []);

    useEffect(() => {
      window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') handleDropdownClose();
      });
      return () => {
        window.removeEventListener('keydown', (e) => {
          if (e.key === 'Escape') handleDropdownClose();
        });
      };
    }, []);

    let newValue = isArray(value) ? value[0] : value;
    if (customeMode) {
      newValue = value;
    }

    return (
      <ConfigProvider
        theme={{
          token: {
            // controlHeight: 40,
          },
        }}
      >
        <DropdownWrapper>
          <AntdSelect
            {...props}
            disabled={disabled}
            className={customeMode ? 'custome-select-none-tag-select' : ''}
            mode={customeMode && 'tags'}
            dropdownStyle={{
              maxHeight: 230,
              overflow: 'auto',
            }}
            ref={ref}
            onDropdownVisibleChange={setOpen}
            open={open}
            suffixIcon={
              <ArrowIcon disabled={disabled} className="ant-select-icon" />
            }
            options={options}
            showAction={['focus', 'click']}
            value={newValue === '' || customeMode ? [] : newValue}
            searchValue={showSearch ? undefined : searchText}
            onInputKeyDown={handleKeydownAction}
            onSearch={
              showSearch
                ? (e) => {
                    if (customeMode) {
                      setSearchText(e);
                      setSelectIdx(0);
                    }
                    props.onSearch?.(e);
                  }
                : undefined
            }
            onClick={(e) => {
              e.stopPropagation();
              // if (!isMultiSelect) {
              //   setOpen((prev) => !prev);
              // }
            }}
            onChange={!customeMode ? onChange : undefined}
            dropdownRender={renderDropdownMenu}
          />

          {customeMode === CustomMode.MULTI_TAG && value.length > 0 && (
            <LabelWrapper>
              {value.map((item: any, idx: number) => (
                <LabelBox key={idx.toString()}>
                  <Typo typoType="btn3">
                    {options?.find((option) => option.value === item)?.label ||
                      item}
                  </Typo>
                  <CloseIcon onClick={() => (onChange as any)(item)} />
                </LabelBox>
              ))}
            </LabelWrapper>
          )}
        </DropdownWrapper>
      </ConfigProvider>
    );
  },
);

export default Select;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;
// const SelectOptionsWrapper = styled.div`
//   white-space: nowrap;
//   ul li:hover {
//     background-color: ${colorSet.primary9};
//   }
// `;

const SelectOptionsContainer = styled.ul<{
  maxWidth?: number;
  maxHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colorSet.gray13};
  box-shadow: 2px 2px 16px rgba(34, 37, 41, 0.12);
  border-radius: 8px;
  width: ${({ maxWidth }) => `${maxWidth}px` || 'auto'};
  max-width: ${({ maxWidth }) => `${maxWidth}px` || 'auto'};
  max-height: 230px;
  // max-height: ${({ maxHeight }) => `${maxHeight}px` || 'auto'};
  overflow: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;
const SelectItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 12px 10px 16px;
  // &:hover {
  //   background-color: #eee;
  // }
  ${typo.b10};

  > .icon {
    display: none;
    width: 16px;
    height: 16px;
    margin-left: auto;
    background: url('/assets/icons/icon-checked_primary.svg') no-repeat center
      center / contain;
  }

  &[data-checked='true'] {
    p {
      color: ${colorSet.primary3};
    }
    > .icon {
      display: flex;
    }
  }

  &[data-focused='true'] {
    background-color: ${colorSet.primary9};
  }

  &[aria-disabled='true'] {
    > .icon {
      fill: ${colorSet.gray8};
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 2px;

  //   width: 67px;
  height: 32px;

  /* Gray Scale/Gray12, BG */

  background: #f6f8fb;
  border-radius: 100px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 18px;
  height: 18px;
  path {
    fill: ${colorSet.gray2};
  }
`;
