import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fetchPartners } from '@apis/partners';
import { Select } from '@components/atoms';
import { SelectProps } from '@components/atoms/Select';
import { FetchPartnerListQuery } from '../../../@types/partner/params/FetchPartnerListQuery';

interface Props extends SelectProps {
  placeholder?: string;
  mode?: 'tags' | 'multiple';
  value: number | null;
  onChange: (value: number) => void;
  style?: React.CSSProperties;
}
function PartnerSearchSelect({
  placeholder,
  mode,
  value,
  onChange,
  style,
  ...rest
}: Props) {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [partnerName, setPartnerName] = useState<string>('');
  const [partnerOptions, setPartnerOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  const timeoutInstance = useRef<NodeJS.Timeout | null>(null);
  const isInit = useRef(true);

  useEffect(() => {
    if (timeoutInstance.current) {
      clearTimeout(timeoutInstance.current);
    }
    if (!isInit.current) {
      timeoutInstance.current = setTimeout(() => {
        setPartnerName(searchKeyword);
      }, 300);
    } else {
      isInit.current = false;
    }

    return () => {
      if (timeoutInstance.current) {
        clearTimeout(timeoutInstance.current);
      }
    };
  }, [searchKeyword]);

  const dataFetcher = useCallback(async () => {
    const query: FetchPartnerListQuery = {
      page: 1,
      pageSize: 20,
    };
    if (partnerName) {
      query.partnerName = partnerName;
    }
    const { rows } = await fetchPartners(query);

    if (rows?.length > 0) {
      setPartnerOptions(
        rows.map((partner) => ({
          label: partner.displayName || partner.partnerName || '-',
          value: partner.id,
        })),
      );
    } else {
      setPartnerOptions([]);
    }
  }, [partnerName]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  return (
    <Select
      style={style}
      mode={mode}
      allowClear
      value={value}
      options={partnerOptions}
      onChange={onChange}
      placeholder={placeholder || '파트너명을 검색해주세요'}
      showSearch
      onSearch={setSearchKeyword}
      defaultActiveFirstOption={false}
      notFoundContent={null}
      filterOption={false}
      {...rest}
    />
  );
}

export default PartnerSearchSelect;
