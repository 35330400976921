import { AppError, request, Row, Rows } from '@networks/index';
import { FetchPartnerNoticeListQuery } from '../@types/partner-notice/params/FetchPartnerNoticeListQuery';
import { CreatePartnerNoticeBody } from '../@types/partner-notice/params/CreatePartnerNoticeBody';
import { UpdatePartnerNoticeBody } from '../@types/partner-notice/params/UpdatePartnerNoticeBody';
import { PartnerNotice } from '../@types/partner-notice/models/PartnerNotice';

const API_ROOT = 'admin/partner-notices';

export const createPartnerNotice = async (body: CreatePartnerNoticeBody) => {
  try {
    const { data } = await request<Row<PartnerNotice>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchPartnerNotices = async (
  query: FetchPartnerNoticeListQuery,
) => {
  try {
    const { data } = await request<Rows<PartnerNotice>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    return {
      rows: [],
      count: 0,
    };
  }
};

export const fetchPartnerNoticeDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<PartnerNotice>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updatePartnerNotice = async (
  id: number | string,
  body: UpdatePartnerNoticeBody,
) => {
  const url = `${API_ROOT}/${id}`;
  try {
    await request({
      method: 'put',
      url,
      requestBody: body,
    });
  } catch (e) {
    throw new AppError(e);
  }
};

export const deletePartnerNotice = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    await request({
      method: 'delete',
      url,
    });
  } catch (e) {
    throw new AppError(e);
  }
};
