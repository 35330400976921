/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { useQuery } from 'react-query';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import TextArea from '@components/v2/antd/data-entry/TextArea';
import Select from '@components/v2/antd/data-entry/Select';

import {
  createGlobalPrompt,
  deleteGlobalPrompt,
  fetchGlobalPromptDetail,
  updateGlobalPrompt,
} from '../../../../@apis/global-prompts';
import { UpdateGlobalPromptBody } from '../../../../@types/global-prompts/params/UpdateGlobalPromptBody';
import { CreateGlobalPromptBody } from '../../../../@types/global-prompts/params/CreateGlobalPromptBody';
import { fetchPartnerCategories } from '../../../../@apis/partner-categories';
import { GlobalPromptForm } from './types/GlobalPromptForm';
import { useGlobalPromptRoleOptions } from './queries';

const INITIAL_VALUES: GlobalPromptForm = {
  role: undefined,
  message: undefined,
  partnerCategoryId: undefined,
};

function GlobalPromptDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();

  const formValidationSchema = yup.object({
    partnerCategoryId: yup.number().required(),
    role: yup.string().required(),
    message: yup.string().required(),
  });

  const formik = useFormik<GlobalPromptForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      if (id) {
        await updateData(id, { message: values.message });
      } else {
        await addData({
          message: values.message ?? '',
          partnerCategoryId: values.partnerCategoryId ?? -1,
          role: values.role ?? '',
        });
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchGlobalPromptDetail(id);

      setFormInitialValues({
        role: row.role,
        message: row.message,
        partnerCategoryId: row.partnerCategory.id,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const { data: partnerCategoryOptions = [] } = useQuery({
    queryKey: ['fetchPartnerCategories'],
    queryFn: () => fetchPartnerCategories(),
    select: (data) =>
      data.rows.map((d) => ({ label: d.partnerCategoryName, value: d.id })),
  });

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      // await fetchGlobalCatalogCategoryOptions();

      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const { data: promptOptions = [] } = useGlobalPromptRoleOptions();

  const addData = async (body: CreateGlobalPromptBody) => {
    try {
      await createGlobalPrompt(body);
      showToast({
        description: '프롬프트가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: UpdateGlobalPromptBody,
  ) => {
    try {
      await updateGlobalPrompt(id, body);
      showToast({
        description: '프롬프트가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '프롬프트 등록';
    let alertMessage = '작성하신 내용대로 프롬프트를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '프롬프트 수정';
      alertMessage = '작성하신 내용대로 프롬프트를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const handleDeleteClick = (id: string) => {
    showAlert({
      title: '프롬프트 삭제',
      message: '정말 프롬프트를 삭제하시겠습니까?',
      actions: [
        {
          label: '취소',
        },
        {
          label: '삭제',
          color: 'red',
          onClick: async () => {
            await deleteGlobalPrompt(id);
            showToast({
              description: '프롬프트가 삭제되었어요',
              status: 'Primary',
            });
            navigate(-1);
          },
        },
      ],
    });
  };

  const renderTextArea = (info: {
    labelName: string;
    required?: boolean;
    key: keyof GlobalPromptForm;
    placeholder?: string;
  }) => {
    const { labelName, required = false, key, placeholder } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <TextArea
          autoSize={{
            minRows: 4,
            maxRows: 100,
          }}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    disabled?: boolean;
    key: keyof GlobalPromptForm;
    placeholder?: string;
    options: { label: string; value: any }[];
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      options,
      disabled,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          showSearch
          allowClear
          value={formik.values[key] || undefined}
          options={options}
          onChange={(value) => {
            updateForm(key, value || null);
          }}
          placeholder={placeholderText}
          id={key}
          disabled={disabled}
        />
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid;
    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        {id && (
          <Button
            onClick={() => handleDeleteClick(id)}
            buttonStyle="solid"
            buttonColor="red"
          >
            삭제
          </Button>
        )}
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          {/* 시술 기본 정보 */}
          <Card title={'시술 기본정보'}>
            <FormLayout>
              {renderSelectForm({
                labelName: '파트너 카테고리',
                required: true,
                key: 'partnerCategoryId',
                options: partnerCategoryOptions,
                disabled: !!id,
              })}
              {renderSelectForm({
                labelName: '프롬프트 유형',
                required: true,
                key: 'role',
                options: promptOptions,
                disabled: !!id,
              })}
              {renderTextArea({
                labelName: '메시지',
                required: true,
                key: 'message',
              })}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default GlobalPromptDetailPage;
