import React from 'react';
import dayjs from 'dayjs';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Label } from '@components/atoms';
import { PartnerNotice } from '../../../../@types/partner-notice/models/PartnerNotice';

export const noticeListCols: () => ColDef[] = () => [
  {
    field: 'top',
    minWidth: 80,
    width: 80,
    headerName: '상단고정',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<PartnerNotice>) => {
      return (
        <Label colorType={data?.top ? 'black' : 'gray'}>
          {data?.top ? '고정' : '안 함'}
        </Label>
      );
    },
  },
  {
    field: 'viewable',
    minWidth: 80,
    width: 80,
    headerName: '노출여부',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<PartnerNotice>) => {
      return (
        <Label colorType={data?.viewable ? 'blue' : 'gray'}>
          {data?.viewable ? '노출' : '숨김'}
        </Label>
      );
    },
  },
  {
    field: 'title',
    minWidth: 240,
    width: 240,
    flex: 1,
    headerName: '제목',
    sortable: false,
  },
  {
    field: 'partner',
    minWidth: 120,
    width: 120,
    headerName: '파트너',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<PartnerNotice>) => {
      if (data?.partner) {
        return data.partner.partnerName || data.partner.displayName || '-';
      }
      return '-';
    },
  },
  {
    field: 'partnerGroup',
    minWidth: 120,
    width: 120,
    headerName: '파트너그룹',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<PartnerNotice>) => {
      if (data?.partnerGroup) {
        return data.partnerGroup.partnerGroupName || '-';
      }
      return '-';
    },
  },
  {
    field: 'startDate',
    minWidth: 153,
    width: 153,
    headerName: '적용일',
    cellRenderer: ({ value }: ICellRendererParams) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
    sortable: false,
  },
  {
    field: 'endDate',
    minWidth: 153,
    width: 153,
    headerName: '적용종료일',
    cellRenderer: ({ value }: ICellRendererParams) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
    sortable: false,
  },
  {
    field: 'createdAt',
    minWidth: 153,
    width: 153,
    headerName: '등록일',
    cellRenderer: ({ value }: ICellRendererParams) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
    sortable: false,
  },
  {
    field: 'updatedAt',
    minWidth: 153,
    width: 153,
    headerName: '마지막 수정일',
    cellRenderer: ({ value }: ICellRendererParams) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
    sortable: false,
  },
];
