import React, { useCallback, useState } from 'react';
import PaginationTablePage, {
  TableHeaderKeywordFilterInfo,
} from '@components/templates/PaginationTablePage';
import { PAGE_PATHS } from '@routes/constants';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@components/atoms';
import { billingPlanListCols } from '@pages/private/customer/billing-plans/datas';
import { FilterForm } from '@components/organisms/TableFilter/types';
import { fetchBillingPlans } from '../../../../@apis/billing-plans';
import { FetchBillingPlanListQuery } from '../../../../@types/billing-plan/params/FetchBillingPlanListQuery';
import { BillingPlan } from '../../../../@types/billing-plan/models/BillingPlan';
import { IS_ACTIVE_OPTIONS } from '../../../../@types/billing-plan/constants/billing-plan.constants';
import { SetBillingConfigModal } from '../billings/components/SetBillingConfigModal';

function BillingPlanListPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [setBillingConfigModalPartnerId, setSetBillingConfigModalPartnerId] =
    useState<number | null>(null);

  const FILTER_INITIAL_VALUES = {
    partnerName: '',
  };

  const filterForms: FilterForm[] = [
    {
      type: 'select',
      field: 'isActive',
      options: IS_ACTIVE_OPTIONS,
      label: '적용중 여부',
      placeholder: '적용중 여부 선택',
    },
  ];

  const keywordFilterInfo: TableHeaderKeywordFilterInfo<FetchBillingPlanListQuery> =
    {
      filterKey: 'partnerName',
      searchInputPlaceholder: '파트너명 검색',
    };

  const dataFetcher = useCallback(async (query: FetchBillingPlanListQuery) => {
    const { rows, count } = await fetchBillingPlans({
      ...query,
    });
    return {
      rows,
      count,
    };
  }, []);

  const handleTableRowClick = (data: BillingPlan) => {
    const { id } = data;
    navigate(PAGE_PATHS.CUSTOMER_BILLING_PLAN_DETAIL.replace(':id', `${id}`));
  };

  const renderContentRightButton = () => {
    return (
      <RightButtonWrapper>
        <Link to={PAGE_PATHS.CUSTOMER_BILLING_PLAN_ADD}>
          <Button>청구 계획 등록</Button>
        </Link>
      </RightButtonWrapper>
    );
  };

  const onBillingConfigModalClose = useCallback(
    (isRefresh?: boolean) => {
      setSetBillingConfigModalPartnerId(null);
      if (isRefresh) {
        navigate(pathname);
      }
    },
    [pathname, navigate],
  );

  const handleSetBillingConfig = (data: BillingPlan) => {
    const { partner } = data;
    if (partner?.id) {
      setSetBillingConfigModalPartnerId(partner?.id);
    }
  };

  return (
    <>
      <PaginationTablePage
        headerActions={renderContentRightButton()}
        dataFetcher={dataFetcher}
        columns={billingPlanListCols({
          handleSetBillingConfig,
        })}
        filterInitialValues={FILTER_INITIAL_VALUES}
        onRowClick={(row, column: any) => {
          if (column?.colDef?.field === 'billingConfig') {
            if (
              row?.partner?.billingConfig &&
              (row.partner.billingConfig.buyerName ||
                row.partner.billingConfig.buyerEmail ||
                row.partner.billingConfig.buyerTel)
            ) {
              handleTableRowClick(row);
            }
          } else {
            handleTableRowClick(row);
          }
        }}
        keywordFilterInfo={keywordFilterInfo}
        filterForms={filterForms}
      />
      {setBillingConfigModalPartnerId && (
        <SetBillingConfigModal
          partnerId={setBillingConfigModalPartnerId}
          onClose={onBillingConfigModalClose}
        />
      )}
    </>
  );
}

export default BillingPlanListPage;
