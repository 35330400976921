import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '@components/atoms';
import Select from '@components/v2/antd/data-entry/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { styled } from 'styled-components';
import { FormItem } from '@components/molecules';
import { useMutation, useQueryClient } from 'react-query';
import { showSuccessToast } from '@networks/utils';
import { virtualCallsQueryKeys } from '@apis/virtual-calls/queryKeys';
import {
  addOvMember,
  fetchOvMembers,
  fetchSystemAuthorities,
} from '../../../../../@apis/members';

const formValidationSchema = yup.object({
  memberId: yup.number().required('마케팅 채널을 선택해주세요'),
  memberAuthorityId: yup.number().required(),
});

export interface AddMemberModalProps {
  partnerId: number;
  onClose?: () => void;
  onDataUpdated?: () => void;
}

function AddMemberModal(props: AddMemberModalProps) {
  const { onClose, partnerId, onDataUpdated } = props;
  const queryClient = useQueryClient();
  const [dataFetching, setDataFetching] = useState(false);
  const [memberOptions, setMemberOptions] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);
  const [authorityOptions, setAuthorityOptions] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);

  const fetchMemberAuthorityOptions = useCallback(async () => {
    try {
      const { rows } = await fetchSystemAuthorities(partnerId);
      setAuthorityOptions(
        rows.map(({ authorityName, id }) => ({
          label: authorityName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, [partnerId]);

  const fetchMemberOptions = useCallback(async () => {
    try {
      const { rows } = await fetchOvMembers();
      setMemberOptions(
        rows.map(({ memberName, memberAId, id }) => ({
          label: `${memberName}(${memberAId})`,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      await fetchMemberOptions();
      await fetchMemberAuthorityOptions();
      setDataFetching(false);
    })();
  }, [fetchMemberAuthorityOptions, fetchMemberOptions]);

  const { mutate, isLoading } = useMutation({
    mutationFn: addOvMember,
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        virtualCallsQueryKeys.partner(partnerId),
      );
      showSuccessToast('사용자가 등록 되었어요');
      onDataUpdated?.();
      onClose?.();
    },
  });

  const formik = useFormik<{
    memberAuthorityId: number | undefined;
    memberId: number | undefined;
  }>({
    initialValues: {
      memberAuthorityId: undefined,
      memberId: undefined,
    },
    onSubmit: (values) => {
      const { memberId, memberAuthorityId } = values;
      if (memberId && memberAuthorityId) {
        mutate({
          partnerId,
          memberId,
          memberAuthorityId,
        });
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  return (
    <Modal
      size={560}
      confirmLabel="확인"
      cancelLabel="취소"
      disabled={!formik.isValid || isLoading}
      title="OV/OMA 등록"
      onConfirm={() => {
        formik.handleSubmit();
      }}
      onClose={() => {
        onClose?.();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormLayout>
          <FormItem label={'등록 사용자'}>
            <Select
              style={{ width: '100%' }}
              autoFocus
              allowClear
              options={memberOptions}
              onChange={(value) => {
                formik.setFieldValue('memberId', value);
              }}
              placeholder={'사용자를 선택해주세요'}
            />
          </FormItem>
          <FormItem label={'권한'}>
            <Select
              style={{ width: '100%' }}
              allowClear
              options={authorityOptions}
              onChange={(value) => {
                formik.setFieldValue('memberAuthorityId', value);
              }}
              placeholder={'권한을 선택해주세요'}
            />
          </FormItem>
        </FormLayout>
      </form>
    </Modal>
  );
}

export default AddMemberModal;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
