import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { useAlertStore } from '@store/useAlertStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import {
  createPricingPlan,
  fetchPricingPlanDetail,
  updatePricingPlan,
} from '@apis/pricing-plan';
import { ConfigProvider, Input, List, Table, TableColumnsType } from 'antd';
import Skeleton from '@components/molecules/Skeleton';
import { CreatePricingPlanBody } from '../../../../@types/pricing-plan/params/CreatePricingPlanBody';
import { PricingPlanForm } from './types/PricingPlanForm';
import { Menu } from '../../../../@types/menu/models/Menu';
import { UpdatePricingPlanBody } from '../../../../@types/pricing-plan/params/UpdatePricingPlanBody';
import {
  MENU_TRANSLATIONS,
  MenuUserGroup,
} from '../../../../@types/menu/constants/menu.constants';
import { fetchMenus } from '../../../../@apis/menus';
import { RightButtonWrapper } from '../../../../components/utils/layout-utils';
import { Partner } from '../../../../@types/partner/models/Partner';

const INITIAL_VALUES: PricingPlanForm = {
  title: '',
  description: '',
  homepageMenuIds: [],
};

function PricingPlanDetailPage() {
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const showAlert = useAlertStore((state) => state.show);
  const { showToast } = useToastStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [partners, setPartners] = useState<Partner[]>([]);

  const formValidationSchema = yup.object({
    title: yup.string().required(),
    description: yup.string(),
    homepageMenuIds: yup.array().min(0),
  });

  const formik = useFormik<PricingPlanForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      setFormSubmitting(true);
      if (id) {
        await updateData(id, values);
      } else {
        await addData(values);
      }
      setFormSubmitting(false);
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const columns: TableColumnsType<Menu> = [
    {
      title: '메뉴명',
      dataIndex: 'menuName',
      width: 'flex',
    },
    {
      title: '사용자 그룹',
      dataIndex: 'memberGroups',
      render: (_, record) => {
        const { memberGroups = [] } = record;

        if (memberGroups.length === Object.values(MenuUserGroup).length) {
          return '전체';
        }

        return memberGroups
          .map((data) => {
            return MENU_TRANSLATIONS[data] || '';
          })
          .join(', ');
      },
    },
  ];

  const addData = async (body: CreatePricingPlanBody) => {
    try {
      await createPricingPlan(body);
      showToast({
        description: '권한그룹 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number | string,
    body: UpdatePricingPlanBody,
  ) => {
    try {
      await updatePricingPlan(id, body);
      showToast({
        description: '권한그룹 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchPricingPlanDetail(id);
      const { homepageMenus, partners, title, description } = row;

      setFormInitialValues({
        title,
        description,
        homepageMenuIds: homepageMenus.map(({ id }) => {
          return id;
        }),
      });
      setPartners(partners || []);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const requestFetchMenus = useCallback(async () => {
    try {
      const { rows } = await fetchMenus({
        pageSize: 9999,
      });
      setMenus(rows);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      }
      await requestFetchMenus();

      setDataFetching(false);
    })();
  }, [id, fetchDetailData, requestFetchMenus]);

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '프라이싱 플랜 등록';
    let alertMessage = '작성하신 내용대로 프라이싱 플랜을 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '권한그룹 정보 수정';
      alertMessage = '작성하신 내용대로 프라이싱 플랜을 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderPartnerForm = () => {
    return (
      <FormItem label={'사용병원리스트'} optional={false}>
        <List
          size="small"
          dataSource={partners}
          renderItem={(item) => (
            <List.Item>{item.displayName || item.partnerName}</List.Item>
          )}
        />
      </FormItem>
    );
  };

  const renderMenuListForm = () => {
    return (
      <FormItem label={'메뉴 설정'} optional={false}>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                rowSelectedBg: '#FAFAFA',
                rowHoverBg: '#FAFAFA',
                rowSelectedHoverBg: '#FAFAFA',
              },
            },
          }}
        >
          <Table
            pagination={false}
            style={{
              width: '100%',
            }}
            rowSelection={{
              selectedRowKeys: formik.values.homepageMenuIds,
              onChange: (newSelectedRowKeys) => {
                formik.setFieldValue('homepageMenuIds', newSelectedRowKeys);
              },
            }}
            columns={columns}
            dataSource={menus.map((menu) => {
              return {
                ...menu,
                key: menu.id,
              };
            })}
          />
        </ConfigProvider>
      </FormItem>
    );
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PricingPlanForm;
    placeholder?: string;
    disabled?: boolean;
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      disabled = false,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Input
          disabled={disabled}
          id={key}
          name={key}
          value={formik.values[key] as string}
          onChange={(e) => {
            updateForm(key, e.target.value);
          }}
          className="input-container"
          placeholder={placeholderText}
        />
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid || formSubmitting;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty || formSubmitting;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          {/* 시술 기본 정보 */}
          <Card title={'기본정보'}>
            <FormLayout>
              {renderInputForm({
                labelName: '프라이싱명',
                required: true,
                key: 'title',
              })}
              {renderInputForm({
                labelName: '설명',
                required: true,
                key: 'description',
              })}
              {renderPartnerForm()}
              {renderMenuListForm()}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default PricingPlanDetailPage;
