import React from 'react';
import { Input, Modal } from '@components/atoms';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useToastStore } from '@store/useToastStore';
import { FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { setBillingConfig } from '@apis/billing-config';
import { SetBillingConfigBody } from '../../../../../@types/billing-config/params/SetBillingConfigBody';

interface SetBillingConfigFormValues
  extends Omit<SetBillingConfigBody, 'partnerId'> {
  partnerId: number | null;
}
interface Props {
  onClose?: (isRefresh?: boolean) => void;
  partnerId: number;
}

const INITIAL_VALUES: SetBillingConfigFormValues = {
  partnerId: null,
  buyerName: '',
  buyerEmail: '',
  buyerTel: '',
};

export const SetBillingConfigModal = (props: Props) => {
  const { onClose, partnerId } = props;
  const { showToast } = useToastStore();

  const { mutate, isLoading } = useMutation({
    mutationFn: setBillingConfig,
    onSuccess: async () => {
      showToast({
        description: '구매 담당자 정보가 수정되었어요',
        status: 'Primary',
      });
      onClose?.(true);
    },
  });

  const formValidationSchema = yup.object({
    partnerId: yup.number().required(),
    buyerName: yup.string().optional(),
    buyerEmail: yup.string().email().optional(),
    buyerTel: yup.string().optional(),
  });

  const formik = useFormik<SetBillingConfigFormValues>({
    initialValues: { ...INITIAL_VALUES, partnerId },
    onSubmit: (values) => {
      mutate({
        partnerId,
        buyerName: values.buyerName || null,
        buyerEmail: values.buyerEmail || null,
        buyerTel: values.buyerTel || null,
      });
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  return (
    <Modal
      size={560}
      confirmLabel="확인"
      cancelLabel="취소"
      disabled={!formik.isValid || !formik.dirty || isLoading}
      title="구매 담당자 정보 설정"
      onConfirm={() => {
        formik.submitForm();
      }}
      onClose={() => {
        onClose?.();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormLayout>
          <FormItem label={'담당자명'}>
            <Input
              onChange={(ev) => {
                formik.setFieldValue('buyerName', ev.target.value);
              }}
              placeholder={'담당자명을 입력해주세요.'}
            />
          </FormItem>
          <FormItem label={'담당자 이메일'}>
            <Input
              onChange={(ev) => {
                formik.setFieldValue('buyerEmail', ev.target.value);
              }}
              placeholder={'담당자 이메일을 입력해주세요.'}
            />
          </FormItem>
          <FormItem label={'담당자 전화번호'}>
            <Input
              onChange={(ev) => {
                formik.setFieldValue('buyerTel', ev.target.value);
              }}
              placeholder={'담당자 전화번호를 입력해주세요.'}
            />
          </FormItem>
        </FormLayout>
      </form>
    </Modal>
  );
};

export default SetBillingConfigModal;

export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
