import { AppError, request, Row } from '@networks/index';
import { SetBillingConfigBody } from '../@types/billing-config/params/SetBillingConfigBody';
import { BillingConfig } from '../@types/billing-config/models/BillingConfig';

const API_ROOT = 'admin/billing-configs';

export const fetchBillingConfig = async (partnerId: number) => {
  const url = `${API_ROOT}/${partnerId}`;
  try {
    const { data } = await request<Row<BillingConfig>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const setBillingConfig = async (body: SetBillingConfigBody) => {
  try {
    await request({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
  } catch (e) {
    throw new AppError(e);
  }
};
