export const IS_PAID_OPTIONS = [
  {
    label: '결제완료',
    value: true,
  },
  {
    label: '미결제',
    value: false,
  },
];

export const CARD_CODE_NAME: { [key: string]: string } = {
  '01': '하나카드',
  '02': '우리카드',
  '03': '롯데카드',
  '04': '현대카드',
  '06': '국민카드',
  '11': '비씨카드',
  '12': '삼성카드',
  '14': '신한카드',
  '15': '한미카드',
  '16': 'NH카드',
  '17': '하나SK카드',
  '21': 'VISA',
  '22': 'MASTER',
  '23': 'JCB',
  '24': '아멕스',
  '25': '다이너스',
  '26': '은련카드',
  '91': '네이버포인트',
  '93': '토스머니',
  '94': 'SSG머니',
  '96': '엘포인트',
  '97': '카카오머니',
  '98': '페이코포인트',
};
