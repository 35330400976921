/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  Card,
  ContentLayout,
  FormItem,
  PartnerSearchSelect,
  Skeleton,
} from '@components/molecules';
import { styled } from 'styled-components';
import dayjs from 'dayjs';
import { Button, Select, Typo } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import DatePicker from '@components/atoms/DatePicker';
import HtmlEditor from '@components/molecules/HtmlEditor';
import { useAlertStore } from '@store/useAlertStore';
import { useToastStore } from '@store/useToastStore';
import Input from '@components/v2/antd/data-entry/Input';
import { Switch } from 'antd';
import {
  createPartnerNotice,
  deletePartnerNotice,
  fetchPartnerNoticeDetail,
  updatePartnerNotice,
  fetchPartnerGroups,
} from '@apis';
import { CreatePartnerNoticeBody } from '../../../../@types/partner-notice/params/CreatePartnerNoticeBody';
import { UpdatePartnerNoticeBody } from '../../../../@types/partner-notice/params/UpdatePartnerNoticeBody';
import { PartnerNotice } from '../../../../@types/partner-notice/models/PartnerNotice';

interface InitialFormValues
  extends Omit<
    CreatePartnerNoticeBody,
    'startDate' | 'endDate' | 'partnerId' | 'partnerGroupId'
  > {
  startDate?: string | null;
  endDate?: string | null;
  partnerId: number | null;
  partnerGroupId: number | null;
}

const INITIAL_VALUES: InitialFormValues = {
  title: '',
  viewable: false,
  top: false,
  startDate: null,
  endDate: null,
  body: '',
  partnerId: null,
  partnerGroupId: null,
};

function NoticeDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToastStore();
  const showAlert = useAlertStore((state) => state.show);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [dataFetching, setDataFetching] = useState(false);
  const [partnerGroupOptions, setPartnerGroupOptions] = useState<
    { label: string; value: any }[]
  >([]);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const [partnerNoticeDetail, setPartnerNoticeDetail] =
    useState<PartnerNotice | null>(null);

  const formValidationSchema = yup.object({
    viewable: yup.boolean().required(),
    top: yup.boolean().required(),
    title: yup.string().required(),
    body: yup.string().required(),
    startDate: yup
      .mixed()
      .nullable()
      .test(
        'is-string-or-null',
        'YYYY-MM-DD HH:mm:ss 형식 또는 null이어야 합니다',
        (value) => value === null || typeof value === 'string',
      )
      .optional(),
    endDate: yup
      .mixed()
      .nullable()
      .test(
        'is-string-or-null',
        'YYYY-MM-DD HH:mm:ss 형식 또는 null이어야 합니다',
        (value) => value === null || typeof value === 'string',
      )
      .optional(),
    partnerId: yup.number().nullable().optional(),
    partnerGroupId: yup.number().nullable().optional(),
  });

  const onFormSubmit = useCallback(
    async (values: InitialFormValues) => {
      const {
        title,
        body,
        viewable,
        top,
        startDate,
        endDate,
        partnerId,
        partnerGroupId,
      } = values;

      if (id) {
        await updateData(Number(id), {
          viewable: viewable || false,
          top: top || false,
          title: title || '',
          body: body || '',
          startDate: startDate
            ? dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')
            : undefined,
          endDate: endDate
            ? dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')
            : undefined,
        });
      } else {
        await addData({
          viewable: viewable || false,
          top: top || false,
          title: title || '',
          body: body || '',
          startDate: startDate
            ? dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')
            : undefined,
          endDate: endDate
            ? dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')
            : undefined,
          partnerId: partnerId || undefined,
          partnerGroupId: partnerGroupId || undefined,
        });
      }
    },
    [id],
  );

  const formik = useFormik<InitialFormValues>({
    initialValues: formInitialValues,
    onSubmit: onFormSubmit,
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchPartnerGroupOptions = useCallback(async () => {
    try {
      const { rows } = await fetchPartnerGroups({
        page: 1,
        pageSize: 9999,
      });
      setPartnerGroupOptions(
        rows.map(({ partnerGroupName, id }) => ({
          label: partnerGroupName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsInitialLoading(false);
    }
  }, []);

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      setDataFetching(true);
      const { row } = await fetchPartnerNoticeDetail(id);
      const { title, body, viewable, top, startDate, endDate } = row;

      setPartnerNoticeDetail(row);
      setFormInitialValues({
        ...INITIAL_VALUES,
        viewable: viewable || false,
        top: top || false,
        title: title || '',
        body: body || '',
        startDate: startDate
          ? dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')
          : null,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD HH:mm:ss') : null,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDataFetching(false);
      setIsInitialLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!id) {
      (async function fetch() {
        await fetchPartnerGroupOptions();
      })();
      return;
    }
    (async function fetch() {
      await fetchDetailData(id);
    })();
  }, [id]);

  const addData = async (body: CreatePartnerNoticeBody) => {
    try {
      await createPartnerNotice(body);
      showToast({
        description: '공지사항이 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (id: number, body: UpdatePartnerNoticeBody) => {
    try {
      await updatePartnerNotice(id, body);
      showToast({
        description: '공지사항이 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const requestDeleteNotice = async (id: number) => {
    try {
      await deletePartnerNotice(id);
      showToast({
        description: '공지사항이 삭제되었습니다',
        status: 'Red',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteClick = () => {
    if (id) {
      showAlert({
        size: 360,
        title: '공지사항 삭제',
        message: '이 공지사항을 삭제하시겠어요?',
        actions: [
          { label: '취소' },
          {
            label: '삭제',
            color: 'red',
            onClick: async () => {
              requestDeleteNotice(Number(id));
            },
          },
        ],
      });
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = useCallback(() => {
    let alertTitle = '공지사항 등록';
    let alertMessage = '작성하신 내용대로 공지사항을 등록하시겠어요?';
    let actionLabel = '등록';

    if (id) {
      alertTitle = '공지사항 수정';
      alertMessage = '작성하신 내용대로 공지사항을 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  }, [id]);

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    isNumber?: boolean;
    key: keyof InitialFormValues;
    placeholder?: string;
    numberMode?: boolean;
    disabled?: boolean;
    infoLabel?: string;
    suffix?: string;
  }) => {
    const {
      labelName,
      required = false,
      isNumber = false,
      disabled,
      key,
      placeholder,
      infoLabel,
      suffix,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    const value = formik.values[key] as string;

    return (
      <FormItem label={labelName} optional={!required}>
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Input
            id={key}
            name={key}
            value={value ?? ''}
            onChange={(e) => {
              let onChangeValue: string | number = e.target.value;
              if (isNumber) {
                onChangeValue = e.target.value.replace(/(?!^-)[^\d]/g, '');
                onChangeValue = Number(onChangeValue);
              }
              updateForm(key, onChangeValue);
            }}
            className="input-container"
            placeholder={placeholderText}
            disabled={disabled}
            suffix={suffix}
          />
          {infoLabel && (
            <div
              style={{
                marginTop: 4,
              }}
            >
              <Typo typoType="b12m" color="gray7">
                {infoLabel}
              </Typo>
            </div>
          )}
        </div>
      </FormItem>
    );
  };

  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof InitialFormValues;
    placeholder?: string;
    disabled?: boolean;
    options: { label: string; value: any }[];
    mode?: 'tags' | 'multiple';
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      disabled,
      options,
      mode,
    } = info;
    let placeholderText = `${labelName} 선택`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          mode={mode}
          showSearch
          allowClear
          disabled={disabled}
          value={formik.values[key]}
          options={options}
          onChange={(value) => {
            if (value === null) {
              updateForm(key, null);
            } else {
              updateForm(key, value);
            }
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderDatePickerForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof InitialFormValues;
    placeholder?: string;
    disabled?: boolean;
    minDate?: dayjs.Dayjs;
    maxDate?: dayjs.Dayjs;
  }) => {
    const {
      labelName,
      required = false,
      disabled,
      key,
      placeholder,
      minDate,
      maxDate,
    } = info;

    const placeholderText = placeholder || `${labelName} 선택`;
    const value = formik.values[key] as string;

    return (
      <FormItem label={labelName} optional={!required}>
        <DatePicker
          style={{ height: '32px', width: '100%' }}
          allowClear
          showTime
          minDate={minDate}
          maxDate={maxDate}
          value={value ? dayjs(value) : undefined}
          disabled={disabled}
          placeholder={placeholderText}
          onChange={(date: any) => {
            if (date) {
              updateForm(key, dayjs(date).format('YYYY-MM-DD HH:mm:ss'));
            } else {
              updateForm(key, null);
            }
          }}
        />
      </FormItem>
    );
  };

  const renderFormActions = useCallback(() => {
    let saveButtonDisabled = !formik.isValid;
    let showDeleteButton = false;
    let showSaveButton = false;

    if (id && !isInitialLoading) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
      if (!dataFetching) {
        showDeleteButton = true;
      }
    }

    if (!dataFetching) {
      showSaveButton = true;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        {showDeleteButton && (
          <Button
            buttonStyle="solid"
            buttonColor="red"
            onClick={handleDeleteClick}
          >
            삭제
          </Button>
        )}
        {showSaveButton && (
          <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
            저장
          </Button>
        )}
      </RightButtonWrapper>
    );
  }, [formik.isValid, formik.dirty, id, dataFetching, isInitialLoading]);

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card title={'기본정보'}>
            <FormLayout>
              <div className="flex max-w-[648px] gap-2 justify-between">
                <FormItem label="상단 고정 여부">
                  <Switch
                    checked={formik.values.top as boolean}
                    onChange={(checked) => {
                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        top: checked,
                      }));
                    }}
                  />
                </FormItem>
                <FormItem label="노출 여부">
                  <Switch
                    checked={formik.values.viewable as boolean}
                    onChange={(checked) => {
                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        viewable: checked,
                      }));
                    }}
                  />
                </FormItem>
              </div>

              {partnerNoticeDetail?.partnerGroup ? (
                <FormItem label="파트너 그룹" optional>
                  <Input
                    value={
                      partnerNoticeDetail?.partnerGroup.partnerGroupName || '-'
                    }
                    readOnly
                    disabled
                  />
                </FormItem>
              ) : (
                renderSelectForm({
                  labelName: '파트너 그룹',
                  key: 'partnerGroupId',
                  options: partnerGroupOptions,
                  disabled: !!id,
                  required: false,
                })
              )}

              <FormItem label="파트너" optional>
                {partnerNoticeDetail?.partner ? (
                  <Input
                    value={partnerNoticeDetail.partner.partnerName || '-'}
                    readOnly
                    disabled
                  />
                ) : (
                  <PartnerSearchSelect
                    mode="tags"
                    disabled={!!id}
                    value={formik.values.partnerId}
                    onChange={(partnerId) => {
                      if (partnerId === null) {
                        updateForm('partnerId', null);
                      } else {
                        updateForm('partnerId', partnerId);
                      }
                    }}
                  />
                )}
              </FormItem>

              {renderDatePickerForm({
                labelName: '적용일',
                required: false,
                key: 'startDate',
                minDate: dayjs(),
                maxDate: formik.values.endDate
                  ? dayjs(formik.values.endDate)
                  : undefined,
              })}

              {renderDatePickerForm({
                labelName: '적용종료일',
                required: false,
                key: 'endDate',
                minDate: formik.values.startDate
                  ? dayjs(formik.values.startDate)
                  : dayjs(),
              })}

              {renderInputForm({
                labelName: '제목',
                required: true,
                key: 'title',
              })}

              <FormItem label={'내용'}>
                <HtmlEditor
                  uploadFolder="PARTNER_NOTICE"
                  placeholder="내용을 입력해주세요"
                  data={formik.values.body}
                  onChange={(e) => {
                    updateForm('body', e);
                  }}
                />
              </FormItem>
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div<{ $noGap?: boolean }>`
  display: grid;
  width: 100%;
  gap: ${({ $noGap }) => ($noGap ? 0 : 24)}px;
`;

export default NoticeDetailPage;
