export const IS_ACTIVE_OPTIONS = [
  {
    label: '적용중',
    value: true,
  },
  {
    label: '미적용',
    value: false,
  },
];
