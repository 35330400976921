import { AppError, request, Row, Rows } from '@networks/index';
import { PricingPlan } from '../@types/pricing-plan/models/PricingPlan';
import { UpdatePricingPlanBody } from '../@types/pricing-plan/params/UpdatePricingPlanBody';

const API_ROOT = 'admin/pricing-plans';

export const fetchPricingPlan = async () => {
  try {
    const { data } = await request<Rows<PricingPlan>>({
      method: 'get',
      url: API_ROOT,
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchPricingPlanDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<PricingPlan>>({
      method: 'get',
      url,
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const createPricingPlan = async (body: UpdatePricingPlanBody) => {
  try {
    const { data } = await request<Row<PricingPlan>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updatePricingPlan = async (
  id: number | string,
  body: UpdatePricingPlanBody,
) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<PricingPlan>>({
      method: 'put',
      url,
      requestBody: body,
    });

    return data;
  } catch (e) {
    throw new AppError(e);
  }
};
