import { AppError, request, Row, Rows } from '@networks/index';
import { FetchBillingPlanListQuery } from '../@types/billing-plan/params/FetchBillingPlanListQuery';
import { CreateBillingPlanBody } from '../@types/billing-plan/params/CreateBillingPlanBody';
import { UpdateBillingPlanUseBody } from '../@types/billing-plan/params/UpdateBillingPlanUseBody';
import { BillingPlan } from '../@types/billing-plan/models/BillingPlan';
import { UpdateBillingPlanSettingBody } from '../@types/billing-plan/params/UpdateBillingPlanSettingBody';

const API_ROOT = 'admin/billing-plans';

export const createBillingPlan = async (body: CreateBillingPlanBody) => {
  try {
    const { data } = await request<Row<BillingPlan>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchBillingPlans = async (query: FetchBillingPlanListQuery) => {
  try {
    const { data } = await request<Rows<BillingPlan>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    return {
      rows: [],
      count: 0,
    };
  }
};

export const fetchBillingPlanDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<BillingPlan>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateBillingPlanSetting = async (
  id: number | string,
  body: UpdateBillingPlanSettingBody,
) => {
  const url = `${API_ROOT}/${id}/setting`;
  try {
    await request({
      method: 'put',
      url,
      requestBody: body,
    });
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateBillingPlanUse = async (
  id: number | string,
  body: UpdateBillingPlanUseBody,
) => {
  const url = `${API_ROOT}/${id}/use`;
  try {
    await request({
      method: 'put',
      url,
      requestBody: body,
    });
  } catch (e) {
    throw new AppError(e);
  }
};

export const deleteBillingPlan = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    await request({
      method: 'delete',
      url,
    });
  } catch (e) {
    throw new AppError(e);
  }
};
