/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  Card,
  ContentLayout,
  FormItem,
  PartnerSearchSelect,
  Skeleton,
} from '@components/molecules';
import { styled } from 'styled-components';
import { Button, IconButton, Typo } from '@components/atoms';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
  createBillingPlan,
  deleteBillingPlan,
  fetchBillingPlanDetail,
  updateBillingPlanSetting,
} from '@apis/billing-plans';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useToastStore } from '@store/useToastStore';
import Input from '@components/v2/antd/data-entry/Input';
import colorSet from '@styles/colors';
import { Switch } from 'antd';
import { setBillingConfig, fetchBillingConfig } from '@apis';
import DatePicker from '@components/atoms/DatePicker';
import { BillingConfig } from '../../../../@types/billing-config/models/BillingConfig';
import { SetBillingConfigBody } from '../../../../@types/billing-config/params/SetBillingConfigBody';
import { CreateBillingPlanBody } from '../../../../@types/billing-plan/params/CreateBillingPlanBody';
import { UpdateBillingPlanSettingBody } from '../../../../@types/billing-plan/params/UpdateBillingPlanSettingBody';
import {
  BillingPlan,
  BillingPlanItem,
} from '../../../../@types/billing-plan/models/BillingPlan';
import { CARD_CODE_NAME } from '../../../../@types/billing/constants/billing.contstants';

const DEFAULT_BILLING_PLAN_ITEM = {
  itemName: '',
  price: 0,
  note: '',
};

interface InitialFormValues
  extends Omit<SetBillingConfigBody, 'partnerId'>,
    Omit<CreateBillingPlanBody, 'partnerId'> {
  partnerId: number | null;
}

const INITIAL_VALUES: InitialFormValues = {
  partnerId: null,
  title: '',
  productName: 'YYYY년 M월 부스트리 이용료',
  use: false,
  billingDate: 10,
  startDate: null,
  endDate: null,
  billingPlanItems: [{ ...DEFAULT_BILLING_PLAN_ITEM }],
  buyerName: '',
  buyerEmail: '',
  buyerTel: '',
};

function BillingPlanDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToastStore();
  const showAlert = useAlertStore((state) => state.show);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const [billingPlanDetail, setBillingPlanDetail] =
    useState<BillingPlan | null>(null);
  const [partnerBillingConfig, setPartnerBillingConfig] =
    useState<BillingConfig | null>(null);

  const isEditable = useMemo(() => {
    return !id;
  }, [id]);

  const createFormValidationSchema = yup.object({
    productName: yup.string().required(),
    title: yup.string().required(),
    billingDate: yup.number().min(1).max(24).required(),
    startDate: yup
      .mixed()
      .nullable()
      .test(
        'is-string-or-null',
        'YYYY-MM-DD HH:mm:ss 형식 또는 null이어야 합니다',
        (value) => value === null || typeof value === 'string',
      )
      .required(),
    endDate: yup
      .mixed()
      .nullable()
      .test(
        'is-string-or-null',
        'YYYY-MM-DD HH:mm:ss 형식 또는 null이어야 합니다',
        (value) => value === null || typeof value === 'string',
      )
      .optional(),
    use: yup.boolean().optional(),
    billingPlanItems: yup
      .array(
        yup.object({
          itemName: yup.string().required(),
          price: yup.number().required(),
          note: yup.string().optional(),
        }),
      )
      .min(1)
      .required(),
    partnerId: yup.lazy(() => {
      if (id) {
        return yup.number().optional();
      }
      return yup.number().required();
    }),
    buyerName: yup.string().optional(),
    buyerEmail: yup.string().email().optional(),
    buyerTel: yup.string().optional(),
  });

  const updateFormValidationSchema = yup.object({
    startDate: yup
      .mixed()
      .nullable()
      .test(
        'is-string-or-null',
        'YYYY-MM-DD HH:mm:ss 형식 또는 null이어야 합니다',
        (value) => value === null || typeof value === 'string',
      )
      .required(),
    endDate: yup
      .mixed()
      .nullable()
      .test(
        'is-string-or-null',
        'YYYY-MM-DD HH:mm:ss 형식 또는 null이어야 합니다',
        (value) => value === null || typeof value === 'string',
      )
      .optional(),
    use: yup.boolean().optional(),
    partnerId: yup.lazy(() => {
      if (id) {
        return yup.number().optional();
      }
      return yup.number().required();
    }),
    buyerName: yup.string().optional(),
    buyerEmail: yup.string().email().optional(),
    buyerTel: yup.string().optional(),
  });

  const getBillingItemVatPrices = (billingPlanItems: BillingPlanItem[]) => {
    return billingPlanItems.map((item) => {
      const vat = Math.ceil(Number(item.price) * 0.1); // VAT 금액 계산
      return {
        ...item,
        price: Number(item.price) + vat,
      };
    });
  };

  const onFormSubmit = useCallback(
    async (values: InitialFormValues) => {
      const {
        partnerId,
        title,
        productName,
        billingPlanItems,
        billingDate,
        use,
        startDate,
        endDate,
        buyerName,
        buyerEmail,
        buyerTel,
      } = values;

      const vatIncludedBillingItems = getBillingItemVatPrices(
        billingPlanItems as BillingPlanItem[],
      );
      let configBody: SetBillingConfigBody | undefined;

      if (!partnerBillingConfig || !partnerBillingConfig.key) {
        configBody = {
          partnerId: partnerId || 0,
          buyerName: buyerName || null,
          buyerEmail: buyerEmail || null,
          buyerTel: buyerTel || null,
        };
      }

      if (id) {
        await updateData(
          Number(id),
          {
            use: use || false,
            startDate: startDate
              ? dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
            endDate: endDate
              ? dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
          },
          configBody,
        );
      } else {
        await addData(
          {
            partnerId: partnerId || 0,
            title: title || '',
            productName: productName || '',
            billingPlanItems: vatIncludedBillingItems || [],
            billingDate: billingDate || 10,
            use: use || false,
            startDate: startDate
              ? dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
            endDate: endDate
              ? dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
          },
          configBody,
        );
      }
    },
    [id, partnerBillingConfig],
  );

  const formik = useFormik<InitialFormValues>({
    initialValues: formInitialValues,
    onSubmit: onFormSubmit,
    enableReinitialize: true,
    validationSchema: id
      ? updateFormValidationSchema
      : createFormValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      setDataFetching(true);
      const { row } = await fetchBillingPlanDetail(id);
      const {
        partner,
        productName,
        billingDate,
        billingPlanItems = [],
        use,
        startDate,
        endDate,
      } = row;

      let vatExcludedBillingPlanItems: BillingPlanItem[] = [];

      if (billingPlanItems && billingPlanItems.length > 0) {
        vatExcludedBillingPlanItems = billingPlanItems.map(
          (item) =>
            ({
              itemName: item.itemName,
              price: Number(item.price) - Math.ceil(Number(item.price) / 11),
              note: item.note || '',
            } as BillingPlanItem),
        );
      }

      setBillingPlanDetail(row);
      setFormInitialValues({
        ...INITIAL_VALUES,
        partnerId: partner?.id || null,
        productName: productName || '',
        billingDate: billingDate || 10,
        use: use || false,
        startDate: startDate
          ? dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')
          : null,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD HH:mm:ss') : null,
        billingPlanItems: vatExcludedBillingPlanItems,
        buyerName: partner?.billingConfig?.buyerName || '',
        buyerEmail: partner?.billingConfig?.buyerEmail || '',
        buyerTel: partner?.billingConfig?.buyerTel || '',
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDataFetching(false);
      setIsInitialLoading(false);
    }
  }, []);

  const fetchBillingConfigData = useCallback(
    async (partnerId: number | null) => {
      try {
        if (!partnerId) {
          throw new Error();
        }
        const { row } = await fetchBillingConfig(partnerId);
        const { buyerName, buyerEmail, buyerTel } = row;

        setPartnerBillingConfig(row);
        formik.setValues((prevValues) => ({
          ...prevValues,
          buyerName: buyerName || '',
          buyerEmail: buyerEmail || '',
          buyerTel: buyerTel || '',
        }));
      } catch (e) {
        setPartnerBillingConfig(null);
        formik.setValues((prevValues) => ({
          ...prevValues,
          buyerName: '',
          buyerEmail: '',
          buyerTel: '',
        }));
      }
    },
    [],
  );

  useEffect(() => {
    if (!id) {
      setIsInitialLoading(false);
      return;
    }
    (async function fetch() {
      await fetchDetailData(id);
    })();
  }, [id]);

  useEffect(() => {
    (async function fetch() {
      await fetchBillingConfigData(formik.values.partnerId);
    })();
  }, [formik.values.partnerId]);

  const addData = async (
    body: CreateBillingPlanBody,
    configBody?: SetBillingConfigBody,
  ) => {
    try {
      await createBillingPlan(body);
      if (configBody) {
        await setBillingConfig(configBody);
      }
      showToast({
        description: '청구 계획 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number,
    body: UpdateBillingPlanSettingBody,
    configBody?: SetBillingConfigBody,
  ) => {
    try {
      await updateBillingPlanSetting(id, body);
      if (configBody) {
        await setBillingConfig(configBody);
      }
      showToast({
        description: '청구 계획 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const requestDeleteBilling = async (id: number) => {
    try {
      await deleteBillingPlan(id);
      showToast({
        description: '청구 계획이 삭제되었습니다',
        status: 'Red',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteClick = () => {
    if (id) {
      showAlert({
        size: 360,
        title: '청구 계획 삭제',
        message: '이 청구 계획을 삭제하시겠어요?',
        actions: [
          { label: '취소' },
          {
            label: '삭제',
            color: 'red',
            onClick: async () => {
              requestDeleteBilling(Number(id));
            },
          },
        ],
      });
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = useCallback(() => {
    let alertTitle = '청구 계획 등록';
    let alertMessage = '작성하신 내용대로 청구 계획을 등록하시겠어요?';
    let actionLabel = '등록';

    if (id) {
      alertTitle = '청구 계획 수정';
      alertMessage = '작성하신 내용대로 청구 계획을 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  }, [id, isEditable]);

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const updateBillingItem = useCallback(
    <K extends keyof BillingPlanItem>(
      subKey: K,
      index: number,
      value: BillingPlanItem[K],
    ) => {
      const updatedBillingPlanItems = [
        ...(formik.values.billingPlanItems || []),
      ];
      const updatedItem = {
        ...updatedBillingPlanItems[index],
      } as BillingPlanItem;
      updatedItem[subKey] = value;
      updatedBillingPlanItems.splice(index, 1, updatedItem);
      formik.setValues((prevValues) => ({
        ...prevValues,
        billingPlanItems: updatedBillingPlanItems,
      }));
    },
    [formik.values.billingPlanItems],
  );

  const formatNumberWithCommas = useCallback((num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }, []);

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    isNumber?: boolean;
    labelViewStyle?: CSSProperties;
    key: keyof InitialFormValues | keyof SetBillingConfigBody;
    subKey?: keyof Pick<BillingPlanItem, 'itemName' | 'price' | 'note'>;
    index?: number;
    placeholder?: string;
    numberMode?: boolean;
    disabled?: boolean;
    infoLabel?: string;
    suffix?: string;
  }) => {
    const {
      labelName,
      required = false,
      isNumber = false,
      disabled,
      key,
      subKey,
      index,
      placeholder,
      infoLabel,
      suffix,
      labelViewStyle,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    let value;
    if (subKey && index !== undefined) {
      value = formik.values.billingPlanItems[index][subKey] as string;
    } else {
      value = formik.values[key] as string;
    }

    const inputValue =
      isNumber && value !== '-0'
        ? formatNumberWithCommas(Number(value) || 0)
        : value ?? '';

    return (
      <FormItem
        label={labelName}
        optional={!required}
        viewStyle={labelViewStyle}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Input
            id={key}
            name={key}
            value={inputValue}
            className={`input-container ${
              isNumber && String(inputValue).startsWith('-')
                ? 'negative'
                : undefined
            }`}
            onChange={(e) => {
              let onChangeValue: string | number = e.target.value;
              if (isNumber) {
                if (e.target.value === '0-') {
                  onChangeValue = '-0';
                } else if (e.target.value.startsWith('-')) {
                  // 음수 처리 추가
                  onChangeValue = e.target.value
                    .replace(/^(?!-)[^0-9]/g, '')
                    .replace(/(?!^)-/g, '')
                    .replace(/[^-\d]/g, '');
                  onChangeValue =
                    Number(onChangeValue.replace('-', '')) * -1 ?? '-0';
                } else {
                  onChangeValue = e.target.value.replace(/\D/g, '');
                  onChangeValue = Number(onChangeValue);
                }
              }
              if (subKey && index !== undefined) {
                updateBillingItem(subKey, index, onChangeValue);
              } else {
                updateForm(key, onChangeValue);
              }
            }}
            placeholder={placeholderText}
            disabled={disabled}
            suffix={suffix}
          />
          {infoLabel && (
            <div
              style={{
                marginTop: 4,
              }}
            >
              <Typo typoType="b12m" color="gray7">
                {infoLabel}
              </Typo>
            </div>
          )}
        </div>
      </FormItem>
    );
  };

  const deleteBillingItem = useCallback(
    (index: number) => {
      const updatedBillingItems = [...(formik.values.billingPlanItems || [])];
      updatedBillingItems.splice(index, 1);
      formik.setValues((prevValues) => ({
        ...prevValues,
        billingPlanItems: updatedBillingItems,
      }));
    },
    [formik.values.billingPlanItems],
  );

  const onClickAddBillingItem = useCallback(() => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      billingPlanItems: [
        ...(prevValues.billingPlanItems || []),
        {
          ...DEFAULT_BILLING_PLAN_ITEM,
        },
      ],
    }));
  }, []);

  const renderFormActions = useCallback(() => {
    let saveButtonDisabled = !formik.isValid;
    let showDeleteButton = false;
    let showSaveButton = false;

    if (id && !isInitialLoading) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
      if (!dataFetching) {
        showDeleteButton = true;
      }
    }
    if (!dataFetching) {
      showSaveButton = true;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        {showDeleteButton && (
          <Button
            buttonStyle="solid"
            buttonColor="red"
            onClick={handleDeleteClick}
          >
            삭제
          </Button>
        )}
        {showSaveButton && (
          <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
            저장
          </Button>
        )}
      </RightButtonWrapper>
    );
  }, [
    formik.isValid,
    formik.dirty,
    id,
    isEditable,
    dataFetching,
    isInitialLoading,
  ]);

  const billingItemRight = useMemo(() => {
    if (!isEditable) return null;
    return (
      <RightButtonWrapper>
        <Button
          onClick={onClickAddBillingItem}
          buttonStyle="line"
          buttonColor="gray"
        >
          추가
        </Button>
      </RightButtonWrapper>
    );
  }, [isEditable]);

  const renderDefaultInfoTitle = useMemo(() => {
    return (
      <div className="flex items-center gap-4">
        <Typo typoType="h3">기본정보</Typo>
        <Typo typoType="b11m" color="gray7">
          매월 1일 자정에 청구서가 등록되며 청구서 이메일이 발송됩니다.
        </Typo>
      </div>
    );
  }, []);

  const renderBillingItemsTitle = useMemo(() => {
    if (formik.values.billingPlanItems.length > 0) {
      const totalPrice = formik.values.billingPlanItems.reduce(
        (acc, item) =>
          String(item.price) === '-0' ? acc + 0 : acc + Number(item.price),
        0,
      );
      const totalPriceWithVat = totalPrice * 1.1;

      return (
        <div className="flex items-center gap-4">
          <Typo typoType="h3">결제 항목</Typo>
          <div className="flex items-center gap-2">
            <Typo typoType="b11m" color="gray7">
              총 {formik.values.billingPlanItems.length}건
            </Typo>
            <Typo typoType="b11m" color="gray9">
              |
            </Typo>
            <Typo typoType="b11m" color="gray7">
              총 금액 {totalPrice?.toLocaleString()}원
            </Typo>
            <Typo typoType="b11m" color="gray9">
              |
            </Typo>
            <Typo typoType="b11m" color="gray7">
              VAT 포함 총 금액 {totalPriceWithVat?.toLocaleString()}원
            </Typo>
          </div>
        </div>
      );
    }
    return '결제 항목';
  }, [formik.values.billingPlanItems]);

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card title={renderDefaultInfoTitle}>
            <FormLayout>
              <FormItem label="파트너">
                {billingPlanDetail?.partner ? (
                  <Input
                    value={billingPlanDetail.partner.partnerName || '-'}
                    readOnly
                    disabled
                  />
                ) : (
                  <PartnerSearchSelect
                    mode="tags"
                    value={formik.values.partnerId}
                    onChange={(partnerId) => {
                      if (partnerId === null) {
                        updateForm('partnerId', null);
                      } else {
                        updateForm('partnerId', partnerId);
                      }
                    }}
                  />
                )}
              </FormItem>
              {renderInputForm({
                labelName: '관리명',
                required: true,
                key: 'title',
                disabled: !isEditable,
              })}

              {renderInputForm({
                labelName: '상품명',
                required: true,
                key: 'productName',
                labelViewStyle: { height: '32px' },
                infoLabel:
                  '자동 등록될 청구서의 상품명입니다. YYYY, M을 입력할 경우 이전달(사용기간)의 년월이 입력됩니다.',
                disabled: !isEditable,
              })}

              {renderInputForm({
                labelName: '매월 결제일',
                required: true,
                key: 'billingDate',
                labelViewStyle: { height: '32px' },
                infoLabel: '1~24일 사이의 날짜를 숫자로 입력해주세요.',
                isNumber: true,
                disabled: !isEditable,
                suffix: '일',
              })}

              <FormItem label="적용 시작일">
                <DatePicker
                  style={{ height: '32px', width: '100%' }}
                  allowClear
                  showTime
                  minDate={dayjs()}
                  maxDate={
                    formik.values.endDate
                      ? dayjs(formik.values.endDate)
                      : undefined
                  }
                  value={
                    formik.values.startDate
                      ? dayjs(formik.values.startDate)
                      : undefined
                  }
                  placeholder="적용 시작일 선택"
                  onChange={(date: any) => {
                    if (date) {
                      updateForm(
                        'startDate',
                        dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
                      );
                    } else {
                      updateForm('startDate', null);
                    }
                  }}
                />
              </FormItem>

              <FormItem label="적용 종료일" optional>
                <DatePicker
                  style={{ height: '32px', width: '100%' }}
                  allowClear
                  showTime
                  minDate={
                    formik.values.startDate
                      ? dayjs(formik.values.startDate)
                      : dayjs()
                  }
                  value={
                    formik.values.endDate
                      ? dayjs(formik.values.endDate)
                      : undefined
                  }
                  placeholder="적용 종료일 선택"
                  onChange={(date: any) => {
                    if (date) {
                      updateForm(
                        'endDate',
                        dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
                      );
                    } else {
                      updateForm('endDate', null);
                    }
                  }}
                />
              </FormItem>
              <FormItem label="사용 여부">
                <Switch
                  // disabled={!isEditable}
                  checked={formik.values.use as boolean}
                  onChange={(checked) => {
                    formik.setValues((prevValues) => ({
                      ...prevValues,
                      use: checked,
                    }));
                  }}
                />
              </FormItem>
            </FormLayout>
          </Card>
          <Card title={'결제정보'}>
            <FormLayout>
              {renderInputForm({
                labelName: '구매자명',
                required: false,
                disabled:
                  !!partnerBillingConfig?.key ||
                  !!billingPlanDetail?.partner?.billingConfig?.key ||
                  !isEditable,
                key: 'buyerName',
              })}
              {renderInputForm({
                labelName: '구매자 이메일',
                required: false,
                disabled:
                  !!partnerBillingConfig?.key ||
                  !!billingPlanDetail?.partner?.billingConfig?.key ||
                  !isEditable,
                key: 'buyerEmail',
              })}
              {renderInputForm({
                labelName: '구매자 전화번호',
                required: false,
                disabled:
                  !!partnerBillingConfig?.key ||
                  !!billingPlanDetail?.partner?.billingConfig?.key ||
                  !isEditable,
                key: 'buyerTel',
              })}
              {partnerBillingConfig &&
                !!partnerBillingConfig.key &&
                partnerBillingConfig.pgInfo && (
                  <FormItem label="결제수단">
                    <div className="flex gap-2">
                      <Typo typoType="h11" color="gray5">
                        {CARD_CODE_NAME[partnerBillingConfig.pgInfo.cardcd] ||
                          '-'}
                      </Typo>
                      {partnerBillingConfig.pgInfo.cardno && (
                        <Typo typoType="b11m" color="gray7">
                          {partnerBillingConfig.pgInfo.cardno}
                        </Typo>
                      )}
                    </div>
                  </FormItem>
                )}
            </FormLayout>
          </Card>

          <Card title={renderBillingItemsTitle} right={billingItemRight}>
            <FormLayout $noGap>
              {formik.values.billingPlanItems.map((item, index) => (
                <ItemContainer key={index.toString()}>
                  {renderInputForm({
                    labelName: '항목명',
                    required: true,
                    key: 'billingPlanItems',
                    subKey: 'itemName',
                    index,
                    disabled: !isEditable,
                  })}
                  {renderInputForm({
                    labelName: '금액 (VAT 별도)',
                    infoLabel:
                      'VAT를 제외한 가격을 숫자로만 입력해주세요. 음수는 할인금액으로 처리됩니다.',
                    labelViewStyle: { height: '32px' },
                    required: true,
                    key: 'billingPlanItems',
                    subKey: 'price',
                    isNumber: true,
                    suffix: '원',
                    index,
                    disabled: !isEditable,
                  })}
                  {renderInputForm({
                    labelName: '비고',
                    required: false,
                    key: 'billingPlanItems',
                    subKey: 'note',
                    index,
                    disabled: !isEditable,
                  })}
                  {isEditable && formik.values.billingPlanItems.length > 1 && (
                    <IconButton
                      className="delete-button"
                      iconType={'delete'}
                      onClick={() => deleteBillingItem(index)}
                    />
                  )}
                </ItemContainer>
              ))}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div<{ $noGap?: boolean }>`
  display: grid;
  width: 100%;
  gap: ${({ $noGap }) => ($noGap ? 0 : 24)}px;
  .input-container.negative input {
    color: ${colorSet.red3};
  }
`;

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 60px 24px 0;
  .delete-button {
    position: absolute;
    top: 0;
    right: 4px;
  }
  &:not(:first-child) {
    padding-top: 24px;
    border-top: 1px solid ${colorSet.gray10};
    .delete-button {
      position: absolute;
      top: 24px;
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

export default BillingPlanDetailPage;
