import React from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Label, Tooltip, Button } from '@components/atoms';
import dayjs from 'dayjs';
import { BillingPlan } from '../../../../@types/billing-plan/models/BillingPlan';
import { CARD_CODE_NAME } from '../../../../@types/billing/constants/billing.contstants';

export const billingPlanListCols: ({
  handleSetBillingConfig,
}: {
  handleSetBillingConfig: (data: BillingPlan) => void;
}) => ColDef[] = ({ handleSetBillingConfig }) => [
  {
    field: 'partnerName',
    minWidth: 160,
    width: 160,
    headerName: '파트너명',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<BillingPlan>) => {
      if (data?.partner) {
        const { partnerName, displayName } = data.partner;
        if (displayName) return displayName;
        return partnerName;
      }
      return '-';
    },
  },
  {
    field: 'title',
    minWidth: 120,
    width: 120,
    headerName: '관리명',
    sortable: false,
  },
  {
    field: 'productName',
    minWidth: 200,
    width: 200,
    flex: 1,
    headerName: '청구서 상품명',
    sortable: false,
  },
  {
    field: 'state',
    minWidth: 80,
    width: 80,
    headerName: '상태',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<BillingPlan>) => {
      return (
        <Label colorType={data?.isActive ? 'blue' : 'gray'}>
          {data?.isActive ? '적용중' : '미적용'}
        </Label>
      );
    },
  },
  {
    field: 'price',
    minWidth: 200,
    width: 200,
    headerName: '금액 (VAT 별도)',
    sortable: false,
    cellStyle: { justifyContent: 'right' },
    cellRenderer: ({ data }: ICellRendererParams<BillingPlan>) => {
      let price = 0;
      data?.billingPlanItems.forEach((item) => {
        price += item.price;
      });
      price = Math.round((price / 11) * 10);
      return `${price.toLocaleString()}원`;
    },
  },
  {
    field: 'billingDate',
    minWidth: 90,
    width: 90,
    headerName: '결제일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<BillingPlan>) => {
      return `${value}일`;
    },
  },
  {
    field: 'billingConfig',
    minWidth: 90,
    width: 90,
    headerName: '결제정보',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<BillingPlan>) => {
      if (
        data?.partner?.billingConfig &&
        (data.partner.billingConfig.buyerName ||
          data.partner.billingConfig.buyerEmail ||
          data.partner.billingConfig.buyerTel)
      ) {
        const { buyerName, buyerEmail, buyerTel, pgInfo } =
          data.partner.billingConfig;

        const tooltip = `담당자명: ${buyerName || '-'}\n이메일: ${
          buyerEmail || '-'
        }\n전화번호: ${buyerTel || '-'}${
          pgInfo && pgInfo.cardcd
            ? `\n결제수단: ${CARD_CODE_NAME[pgInfo.cardcd] || '-'}${
                pgInfo.cardno ? ` ${pgInfo.cardno}` : ''
              }`
            : ''
        }`;
        return (
          <div className="flex w-full items-center justify-center">
            <Tooltip trigger={'hover'} title={'결제정보'} description={tooltip}>
              <Label colorType={'primary'}>정보 보기</Label>
            </Tooltip>
          </div>
        );
      }
      return (
        <div className="flex w-full items-center justify-center">
          <Button
            style={{
              borderRadius: '100px',
            }}
            buttonWidth={60}
            buttonSize={20}
            buttonColor={'gray'}
            onClick={(e) => {
              e.stopPropagation();
              if (data) {
                handleSetBillingConfig(data);
              }
            }}
          >
            설정
          </Button>
        </div>
      );
    },
  },
  {
    field: 'startDate',
    minWidth: 160,
    width: 160,
    headerName: '적용일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<BillingPlan>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
  {
    field: 'createdAt',
    minWidth: 160,
    width: 160,
    headerName: '등록일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<BillingPlan>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
];
