/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  Card,
  ContentLayout,
  FormItem,
  PartnerSearchSelect,
  Skeleton,
} from '@components/molecules';
import { styled } from 'styled-components';
import { Button, IconButton, Typo } from '@components/atoms';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useToastStore } from '@store/useToastStore';
import Input from '@components/v2/antd/data-entry/Input';
import colorSet from '@styles/colors';
import { Switch } from 'antd';
import {
  createBilling,
  deleteBilling,
  fetchBillingDetail,
  updateBilling,
  setBillingConfig,
  fetchBillingConfig,
} from '@apis';
import DatePicker from '@components/atoms/DatePicker';
import {
  BillingItem,
  CreateBillingBody,
} from '../../../../@types/billing/params/CreateBillingBody';
import { UpdateBillingBody } from '../../../../@types/billing/params/UpdeteBillingBody';
import { Billing } from '../../../../@types/billing/models/Billing';
import { BillingConfig } from '../../../../@types/billing-config/models/BillingConfig';
import { SetBillingConfigBody } from '../../../../@types/billing-config/params/SetBillingConfigBody';
import { CARD_CODE_NAME } from '../../../../@types/billing/constants/billing.contstants';

const DEFAULT_BILLING_ITEM = {
  itemName: '',
  price: 0,
  note: '',
};

interface InitialFormValues
  extends Omit<SetBillingConfigBody, 'partnerId'>,
    Omit<CreateBillingBody, 'partnerId'> {
  partnerId: number | null;
}

const INITIAL_VALUES: InitialFormValues = {
  partnerId: null,
  productName: '',
  billingItems: [{ ...DEFAULT_BILLING_ITEM }],
  dueDate: null,
  isRegistration: false,
  buyerName: '',
  buyerEmail: '',
  buyerTel: '',
};

function BillingDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToastStore();
  const showAlert = useAlertStore((state) => state.show);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const [billingDetail, setBillingDetail] = useState<Billing | null>(null);
  const [partnerBillingConfig, setPartnerBillingConfig] =
    useState<BillingConfig | null>(null);

  const isEditable = useMemo(() => {
    if (
      !id ||
      billingDetail?.paidAt === undefined ||
      billingDetail?.paidAt === null
    ) {
      return true;
    }
    return false;
  }, [id, billingDetail?.paidAt]);

  const formValidationSchema = yup.object({
    productName: yup.string().required(),
    dueDate: yup
      .mixed()
      .nullable()
      .test(
        'is-string-or-null',
        'YYYY-MM-DD HH:mm:ss 형식 또는 null이어야 합니다',
        (value) => value === null || typeof value === 'string',
      )
      .optional(),
    isRegistration: yup.boolean().optional(),
    billingItems: yup
      .array(
        yup.object({
          itemName: yup.string().required(),
          price: yup.number().required(),
          note: yup.string().optional(),
        }),
      )
      .min(1)
      .required(),
    partnerId: yup.lazy(() => {
      if (id) {
        return yup.number().optional();
      }
      return yup.number().required();
    }),
    buyerName: yup.string().optional(),
    buyerEmail: yup.string().email().optional(),
    buyerTel: yup.string().optional(),
  });

  const getBillingItemVatPrices = (billingItems: BillingItem[]) => {
    return billingItems.map((item) => {
      const vat = Math.ceil(Number(item.price) * 0.1); // VAT 금액 계산
      return {
        ...item,
        price: Number(item.price) + vat,
      };
    });
  };

  const onFormSubmit = useCallback(
    async (values: InitialFormValues) => {
      const {
        partnerId,
        productName,
        billingItems,
        dueDate,
        isRegistration,
        buyerName,
        buyerEmail,
        buyerTel,
      } = values;

      const vatIncludedBillingItems = getBillingItemVatPrices(billingItems);
      let configBody: SetBillingConfigBody | undefined;

      if (!partnerBillingConfig || !partnerBillingConfig.key) {
        configBody = {
          partnerId: partnerId || 0,
          buyerName: buyerName || null,
          buyerEmail: buyerEmail || null,
          buyerTel: buyerTel || null,
        };
      }

      if (id) {
        await updateData(
          Number(id),
          {
            productName: productName || '',
            billingItems: vatIncludedBillingItems || [],
            dueDate: dueDate
              ? dayjs(dueDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
            isRegistration: isRegistration || false,
          },
          configBody,
        );
      } else {
        await addData(
          {
            partnerId: partnerId || 0,
            productName: productName || '',
            billingItems: vatIncludedBillingItems || [],
            dueDate: dueDate
              ? dayjs(dueDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
            isRegistration: isRegistration || false,
          },
          configBody,
        );
      }
    },
    [id, partnerBillingConfig],
  );

  const formik = useFormik<InitialFormValues>({
    initialValues: formInitialValues,
    onSubmit: onFormSubmit,
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      setDataFetching(true);
      const { row } = await fetchBillingDetail(id);
      const {
        partner,
        productName,
        dueDate,
        isRegistration,
        billingItems = [],
      } = row;

      let vatExcludedBillingItems: BillingItem[] = [];

      if (billingItems && billingItems.length > 0) {
        vatExcludedBillingItems = billingItems.map((item) => ({
          itemName: item.itemName,
          price: Number(item.price) - Math.ceil(Number(item.price) / 11),
          note: item.note || '',
        }));
      }

      setBillingDetail(row);
      setFormInitialValues({
        ...INITIAL_VALUES,
        partnerId: partner?.id || null,
        productName: productName || '',
        dueDate: dueDate ? dayjs(dueDate).format('YYYY-MM-DD HH:mm:ss') : null,
        isRegistration: isRegistration || false,
        billingItems: vatExcludedBillingItems,
        buyerName: partner?.billingConfig?.buyerName || '',
        buyerEmail: partner?.billingConfig?.buyerEmail || '',
        buyerTel: partner?.billingConfig?.buyerTel || '',
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDataFetching(false);
      setIsInitialLoading(false);
    }
  }, []);

  const fetchBillingConfigData = useCallback(
    async (partnerId: number | null) => {
      try {
        if (!partnerId) {
          throw new Error();
        }
        const { row } = await fetchBillingConfig(partnerId);
        const { buyerName, buyerEmail, buyerTel } = row;

        setPartnerBillingConfig(row);
        formik.setValues((prevValues) => ({
          ...prevValues,
          buyerName: buyerName || '',
          buyerEmail: buyerEmail || '',
          buyerTel: buyerTel || '',
        }));
      } catch (e) {
        setPartnerBillingConfig(null);
        formik.setValues((prevValues) => ({
          ...prevValues,
          buyerName: '',
          buyerEmail: '',
          buyerTel: '',
        }));
      }
    },
    [],
  );

  useEffect(() => {
    if (!id) {
      setIsInitialLoading(false);
      return;
    }
    (async function fetch() {
      await fetchDetailData(id);
    })();
  }, [id]);

  useEffect(() => {
    (async function fetch() {
      await fetchBillingConfigData(formik.values.partnerId);
    })();
  }, [formik.values.partnerId]);

  const addData = async (
    body: CreateBillingBody,
    configBody?: SetBillingConfigBody,
  ) => {
    try {
      await createBilling(body);
      if (configBody) {
        await setBillingConfig(configBody);
      }
      showToast({
        description: '청구서 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (
    id: number,
    body: UpdateBillingBody,
    configBody?: SetBillingConfigBody,
  ) => {
    try {
      await updateBilling(id, body);
      if (configBody) {
        await setBillingConfig(configBody);
      }
      showToast({
        description: '청구서 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const requestDeleteBilling = async (id: number) => {
    try {
      await deleteBilling(id);
      showToast({
        description: '청구서가 삭제되었습니다',
        status: 'Red',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteClick = () => {
    if (id) {
      showAlert({
        size: 360,
        title: '청구서 삭제',
        message: '이 청구서를 삭제하시겠어요?',
        actions: [
          { label: '취소' },
          {
            label: '삭제',
            color: 'red',
            onClick: async () => {
              requestDeleteBilling(Number(id));
            },
          },
        ],
      });
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = useCallback(() => {
    if (!isEditable) return;

    let alertTitle = '청구서 등록';
    let alertMessage = '작성하신 내용대로 청구서를 등록하시겠어요?';
    let actionLabel = '등록';

    if (id) {
      alertTitle = '청구서 수정';
      alertMessage = '작성하신 내용대로 청구서를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  }, [id, isEditable]);

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };

  const updateBillingItem = useCallback(
    <K extends keyof BillingItem>(
      subKey: K,
      index: number,
      value: BillingItem[K],
    ) => {
      const updatedBillingItems = [...(formik.values.billingItems || [])];
      const updatedItem = {
        ...updatedBillingItems[index],
      } as BillingItem;
      updatedItem[subKey] = value;
      updatedBillingItems.splice(index, 1, updatedItem);
      formik.setValues((prevValues) => ({
        ...prevValues,
        billingItems: updatedBillingItems,
      }));
    },
    [formik.values.billingItems],
  );

  const formatNumberWithCommas = useCallback((num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }, []);

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    isNumber?: boolean;
    labelViewStyle?: CSSProperties;
    key: keyof InitialFormValues | keyof SetBillingConfigBody;
    subKey?: keyof BillingItem;
    index?: number;
    placeholder?: string;
    numberMode?: boolean;
    disabled?: boolean;
    infoLabel?: string;
    suffix?: string;
  }) => {
    const {
      labelName,
      required = false,
      isNumber = false,
      disabled,
      key,
      subKey,
      index,
      placeholder,
      infoLabel,
      suffix,
      labelViewStyle,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    let value;
    if (subKey && index !== undefined) {
      value = formik.values.billingItems[index][subKey] as string;
    } else {
      value = formik.values[key] as string;
    }

    const inputValue =
      isNumber && value !== '-0'
        ? formatNumberWithCommas(Number(value) || 0)
        : value ?? '';

    return (
      <FormItem
        label={labelName}
        optional={!required}
        viewStyle={labelViewStyle}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Input
            id={key}
            name={key}
            value={inputValue}
            className={`input-container ${
              isNumber && String(inputValue).startsWith('-')
                ? 'negative'
                : undefined
            }`}
            onChange={(e) => {
              let onChangeValue: string | number = e.target.value;
              if (isNumber) {
                if (e.target.value === '0-') {
                  onChangeValue = '-0';
                } else if (e.target.value.startsWith('-')) {
                  // 음수 처리 추가
                  onChangeValue = e.target.value
                    .replace(/^(?!-)[^0-9]/g, '')
                    .replace(/(?!^)-/g, '')
                    .replace(/[^-\d]/g, '');
                  onChangeValue =
                    Number(onChangeValue.replace('-', '')) * -1 ?? '-0';
                } else {
                  onChangeValue = e.target.value.replace(/\D/g, '');
                  onChangeValue = Number(onChangeValue);
                }
              }
              if (subKey && index !== undefined) {
                updateBillingItem(subKey, index, onChangeValue);
              } else {
                updateForm(key, onChangeValue);
              }
            }}
            placeholder={placeholderText}
            disabled={disabled}
            suffix={suffix}
          />
          {infoLabel && (
            <div
              style={{
                marginTop: 4,
              }}
            >
              <Typo typoType="b12m" color="gray7">
                {infoLabel}
              </Typo>
            </div>
          )}
        </div>
      </FormItem>
    );
  };

  const deleteBillingItem = useCallback(
    (index: number) => {
      const updatedBillingItems = [...(formik.values.billingItems || [])];
      updatedBillingItems.splice(index, 1);
      formik.setValues((prevValues) => ({
        ...prevValues,
        billingItems: updatedBillingItems,
      }));
    },
    [formik.values.billingItems],
  );

  const onClickAddBillingItem = useCallback(() => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      billingItems: [
        ...(prevValues.billingItems || []),
        {
          ...DEFAULT_BILLING_ITEM,
        },
      ],
    }));
  }, []);

  const renderFormActions = useCallback(() => {
    let saveButtonDisabled = !formik.isValid;
    let showDeleteButton = false;
    let showSaveButton = false;

    if (id && !isInitialLoading) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
      if (isEditable && !dataFetching) {
        showDeleteButton = true;
      }
    }
    if (isEditable && !dataFetching) {
      showSaveButton = true;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        {showDeleteButton && (
          <Button
            buttonStyle="solid"
            buttonColor="red"
            onClick={handleDeleteClick}
          >
            삭제
          </Button>
        )}
        {showSaveButton && (
          <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
            저장
          </Button>
        )}
      </RightButtonWrapper>
    );
  }, [
    formik.isValid,
    formik.dirty,
    id,
    isEditable,
    dataFetching,
    isInitialLoading,
  ]);

  const billingItemRight = useMemo(() => {
    if (!isEditable) return null;
    return (
      <RightButtonWrapper>
        <Button
          onClick={onClickAddBillingItem}
          buttonStyle="line"
          buttonColor="gray"
        >
          추가
        </Button>
      </RightButtonWrapper>
    );
  }, [isEditable]);

  const renderDefaultInfoTitle = useMemo(() => {
    return (
      <div className="flex items-center gap-4">
        <Typo typoType="h3">기본정보</Typo>
        <Typo typoType="b11m" color="gray7">
          청구서 이메일은 별도로 전송해야 합니다.
        </Typo>
      </div>
    );
  }, []);

  const renderBillingItemsTitle = useMemo(() => {
    if (formik.values.billingItems.length > 0) {
      const totalPrice = formik.values.billingItems.reduce(
        (acc, item) =>
          String(item.price) === '-0' ? acc + 0 : acc + Number(item.price),
        0,
      );
      const totalPriceWithVat = totalPrice * 1.1;

      return (
        <div className="flex items-center gap-4">
          <Typo typoType="h3">결제 항목</Typo>
          <div className="flex items-center gap-2">
            <Typo typoType="b11m" color="gray7">
              총 {formik.values.billingItems.length}건
            </Typo>
            <Typo typoType="b11m" color="gray9">
              |
            </Typo>
            <Typo typoType="b11m" color="gray7">
              총 금액 {totalPrice?.toLocaleString()}원
            </Typo>
            <Typo typoType="b11m" color="gray9">
              |
            </Typo>
            <Typo typoType="b11m" color="gray7">
              VAT 포함 총 금액 {totalPriceWithVat?.toLocaleString()}원
            </Typo>
          </div>
        </div>
      );
    }
    return '결제 항목';
  }, [formik.values.billingItems]);

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card title={renderDefaultInfoTitle}>
            <FormLayout>
              <FormItem label="파트너">
                {billingDetail?.partner ? (
                  <Input
                    value={billingDetail.partner.partnerName || '-'}
                    readOnly
                    disabled
                  />
                ) : (
                  <PartnerSearchSelect
                    mode="tags"
                    value={formik.values.partnerId}
                    onChange={(partnerId) => {
                      if (partnerId === null) {
                        updateForm('partnerId', null);
                      } else {
                        updateForm('partnerId', partnerId);
                      }
                    }}
                  />
                )}
              </FormItem>

              {renderInputForm({
                labelName: '상품명',
                required: true,
                key: 'productName',
                disabled: !isEditable,
              })}

              <FormItem
                label="자동결제 예정일"
                optional
                viewStyle={{ height: '32px' }}
              >
                <div className="flex w-full flex-col gap-1.5">
                  <DatePicker
                    style={{ height: '32px', width: '100%' }}
                    allowClear
                    showTime
                    minDate={dayjs()}
                    value={
                      formik.values.dueDate
                        ? dayjs(formik.values.dueDate)
                        : undefined
                    }
                    disabled={formik.values.isRegistration || !isEditable}
                    placeholder="예정일 선택"
                    onChange={(date: any) => {
                      if (date) {
                        updateForm(
                          'dueDate',
                          dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
                        );
                      } else {
                        updateForm('dueDate', null);
                      }
                    }}
                  />

                  <Typo typoType="b12m" color="gray7">
                    결제수단이 등록되기 전엔 결제 처리되지 않습니다.
                    <br />
                    예정일이 지나고 결제수단이 등록되는 경우 바로 결제
                    처리됩니다.
                  </Typo>
                </div>
              </FormItem>

              {partnerBillingConfig && partnerBillingConfig?.key ? null : (
                <FormItem label="최초 결제 여부" optional>
                  <Switch
                    disabled={!isEditable}
                    checked={formik.values.isRegistration as boolean}
                    onChange={(checked) => {
                      formik.setValues((prevValues) => ({
                        ...prevValues,
                        isRegistration: checked,
                        dueDate: checked ? null : prevValues.dueDate,
                      }));
                    }}
                  />
                </FormItem>
              )}
            </FormLayout>
          </Card>
          <Card title={'결제정보'}>
            <FormLayout>
              {renderInputForm({
                labelName: '구매자명',
                required: false,
                disabled:
                  !!partnerBillingConfig?.key ||
                  !!billingDetail?.partner?.billingConfig?.key ||
                  !isEditable,
                key: 'buyerName',
              })}
              {renderInputForm({
                labelName: '구매자 이메일',
                required: false,
                disabled:
                  !!partnerBillingConfig?.key ||
                  !!billingDetail?.partner?.billingConfig?.key ||
                  !isEditable,
                key: 'buyerEmail',
              })}
              {renderInputForm({
                labelName: '구매자 전화번호',
                required: false,
                disabled:
                  !!partnerBillingConfig?.key ||
                  !!billingDetail?.partner?.billingConfig?.key ||
                  !isEditable,
                key: 'buyerTel',
              })}
              {partnerBillingConfig &&
                !!partnerBillingConfig.key &&
                partnerBillingConfig.pgInfo && (
                  <FormItem label="결제수단">
                    <div className="flex gap-2">
                      <Typo typoType="h11" color="gray5">
                        {CARD_CODE_NAME[partnerBillingConfig.pgInfo.cardcd] ||
                          '-'}
                      </Typo>
                      {partnerBillingConfig.pgInfo.cardno && (
                        <Typo typoType="b11m" color="gray7">
                          {partnerBillingConfig.pgInfo.cardno}
                        </Typo>
                      )}
                    </div>
                  </FormItem>
                )}
            </FormLayout>
          </Card>

          <Card title={renderBillingItemsTitle} right={billingItemRight}>
            <FormLayout $noGap>
              {formik.values.billingItems.map((_, index) => (
                <ItemContainer key={index.toString()}>
                  {renderInputForm({
                    labelName: '항목명',
                    required: true,
                    key: 'billingItems',
                    subKey: 'itemName',
                    index,
                    disabled: !isEditable,
                  })}
                  {renderInputForm({
                    labelName: '금액 (VAT 별도)',
                    infoLabel:
                      'VAT를 제외한 가격을 숫자로만 입력해주세요. 음수는 할인금액으로 처리됩니다.',
                    labelViewStyle: { height: '32px' },
                    required: true,
                    key: 'billingItems',
                    subKey: 'price',
                    isNumber: true,
                    suffix: '원',
                    index,
                    disabled: !isEditable,
                  })}
                  {renderInputForm({
                    labelName: '비고',
                    required: false,
                    key: 'billingItems',
                    subKey: 'note',
                    index,
                    disabled: !isEditable,
                  })}
                  {isEditable && formik.values.billingItems.length > 1 && (
                    <IconButton
                      className="delete-button"
                      iconType={'delete'}
                      onClick={() => deleteBillingItem(index)}
                    />
                  )}
                </ItemContainer>
              ))}
            </FormLayout>
          </Card>
        </FormSectionLayout>
      </form>
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormLayout = styled.div<{ $noGap?: boolean }>`
  display: grid;
  width: 100%;
  gap: ${({ $noGap }) => ($noGap ? 0 : 24)}px;
  .input-container.negative input {
    color: ${colorSet.red3};
  }
`;

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 60px 24px 0;
  .delete-button {
    position: absolute;
    top: 0;
    right: 4px;
  }
  &:not(:first-child) {
    padding-top: 24px;
    border-top: 1px solid ${colorSet.gray10};
    .delete-button {
      position: absolute;
      top: 24px;
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

const InfoWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  svg {
    flex-shrink: 0;
  }
`;

export default BillingDetailPage;
