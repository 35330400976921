import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import { css, styled } from 'styled-components';
import { ButtonColorType, ButtonSizeType, ButtonStyleType } from './types';
import {
  getDefaultColor,
  getDisabledColor,
  getHoveredColor,
  getIconSize,
  getPressedColor,
  getTypoSize,
} from './utils';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?: ButtonStyleType;
  /**
   * @description 56 | 48 | 40 | 32 | 28 | 20;
   */
  buttonSize?: ButtonSizeType;
  buttonColor?: ButtonColorType;
  isFull?: boolean;
  buttonWidth?: number;
  test?: JSX.Element;
}

const Button = forwardRef(
  (
    {
      buttonStyle = 'solid',
      buttonSize = 40,
      buttonColor = 'primary',
      buttonWidth,
      isFull,
      children,
      test,
      type = 'button',
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <StyledButton
        $buttonWidth={buttonWidth}
        $btnStyle={buttonStyle}
        $btnColor={buttonColor}
        $isFull={isFull}
        $size={buttonSize}
        type={type}
        ref={ref}
        {...rest}
      >
        {children}
      </StyledButton>
    );
  },
);

export default Button;

const StyledButton = styled.button<{
  $btnStyle: ButtonStyleType;
  $btnColor: ButtonColorType;
  $size: ButtonSizeType;
  $buttonWidth?: number;
  $isFull?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ $buttonWidth }) =>
    $buttonWidth
      ? css`
          width: ${$buttonWidth}px;
        `
      : css`
          width: 100%;
          max-width: max-content;
          min-width: min-content;
        `}
  cursor: pointer;
  outline: none;
  &:disabled {
    cursor: not-allowed;
  }
  ${({ $isFull }) =>
    $isFull
      ? css`
          max-width: 100%;
        `
      : css``}

  transition: 0.07s all ease-in-out;
  justify-content: center;
  ${({ $size }) => getTypoSize($size)};
  ${({ $btnColor, $btnStyle }) => getDefaultColor($btnColor, $btnStyle)};
  svg > path {
    ${({ $size }) => getIconSize($size)}
    ${({ $btnColor, $btnStyle }) => getDefaultColor($btnColor, $btnStyle)};
  }
  &:focus-visible,
  &:hover {
    ${({ $btnColor, $btnStyle }) => getHoveredColor($btnColor, $btnStyle)};
    svg > path {
      ${({ $btnColor, $btnStyle }) => getHoveredColor($btnColor, $btnStyle)};
    }
  }
  &:active {
    ${({ $btnColor, $btnStyle }) => getPressedColor($btnColor, $btnStyle)};
    svg > path {
      ${({ $btnColor, $btnStyle }) => getPressedColor($btnColor, $btnStyle)};
    }
  }
  &:disabled {
    ${({ $btnColor, $btnStyle }) => getDisabledColor($btnColor, $btnStyle)};
    svg > path {
      ${({ $btnColor, $btnStyle }) => getDisabledColor($btnColor, $btnStyle)};
    }
  }
`;
