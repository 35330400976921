import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Label, Tooltip, Button, IconButton } from '@components/atoms';
import dayjs from 'dayjs';
import React from 'react';
import { Billing } from '../../../../@types/billing/models/Billing';
import { CARD_CODE_NAME } from '../../../../@types/billing/constants/billing.contstants';

export const billingListCols: ({
  handleSetBillingConfig,
  handleCopyUrl,
}: {
  handleSetBillingConfig: (data: Billing) => void;
  handleCopyUrl: (url: string) => void;
}) => ColDef[] = ({ handleCopyUrl, handleSetBillingConfig }) => [
  {
    field: 'partnerName',
    minWidth: 160,
    width: 160,
    headerName: '파트너명',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      if (data?.partner) {
        const { partnerName, displayName } = data.partner;
        if (displayName) return displayName;
        return partnerName;
      }
      return '-';
    },
  },
  {
    field: 'productName',
    minWidth: 200,
    width: 200,
    flex: 1,
    headerName: '청구서 상품명',
    sortable: false,
  },
  {
    field: 'state',
    minWidth: 85,
    width: 85,
    headerName: '결제상태',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      if (data?.paymentCanceledAt) {
        return <Label colorType={'red'}>결제취소</Label>;
      }
      return (
        <Label colorType={data?.paidAt ? 'blue' : 'gray'}>
          {data?.paidAt ? '결제완료' : '미결제'}
        </Label>
      );
    },
  },
  {
    field: 'price',
    minWidth: 200,
    width: 200,
    headerName: '금액 (VAT 포함)',
    sortable: false,
    cellStyle: { justifyContent: 'right' },
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      let price = 0;
      data?.billingItems.forEach((item) => {
        price += item.price;
      });
      return `${price.toLocaleString()}원`;
    },
  },
  {
    field: 'billingConfig',
    minWidth: 90,
    width: 90,
    headerName: '결제정보',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      if (
        data?.partner?.billingConfig &&
        (data.partner.billingConfig.buyerName ||
          data.partner.billingConfig.buyerEmail ||
          data.partner.billingConfig.buyerTel)
      ) {
        const { buyerName, buyerEmail, buyerTel } = data.partner.billingConfig;
        const { paymentPgInfo } = data;

        const tooltip = `담당자명: ${buyerName || '-'}\n이메일: ${
          buyerEmail || '-'
        }\n전화번호: ${buyerTel || '-'}${
          paymentPgInfo && paymentPgInfo.cardCode
            ? `\n결제수단: ${CARD_CODE_NAME[paymentPgInfo.cardCode] || '-'}${
                paymentPgInfo.cardNumber ? ` ${paymentPgInfo.cardNumber}` : ''
              }`
            : ''
        }`;
        return (
          <div className="flex w-full items-center justify-center">
            <Tooltip trigger={'hover'} title={'결제정보'} description={tooltip}>
              <Label colorType={'primary'}>정보 보기</Label>
            </Tooltip>
          </div>
        );
      }
      return (
        <div className="flex w-full items-center justify-center">
          <Button
            style={{
              borderRadius: '100px',
            }}
            buttonWidth={60}
            buttonSize={20}
            buttonColor={'gray'}
            onClick={(e) => {
              e.stopPropagation();
              if (data) {
                handleSetBillingConfig(data);
              }
            }}
          >
            설정
          </Button>
        </div>
      );
    },
  },
  {
    field: 'sentAt',
    minWidth: 90,
    width: 90,
    headerName: '전송',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      if (data?.sentAt) {
        return (
          <Tooltip
            trigger={'hover'}
            title={'전송일자'}
            description={dayjs(data.sentAt).format('YYYY-MM-DD HH:mm')}
          >
            <Label colorType={'primary'}>완료</Label>
          </Tooltip>
        );
      }
      return <Label colorType={'gray'}>미전송</Label>;
    },
  },
  {
    field: 'url',
    minWidth: 80,
    width: 80,
    headerName: '결제URL',
    sortable: false,
    cellRenderer: ({ data }: ICellRendererParams<Billing>) => {
      return data?.url ? (
        <div className="flex w-full items-center justify-center">
          <IconButton
            iconType={'copy'}
            size="sm"
            onClick={() => handleCopyUrl(data.url!)}
          />
        </div>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'dueDate',
    minWidth: 160,
    width: 160,
    headerName: '결제예정일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<Billing>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
  {
    field: 'paidAt',
    minWidth: 160,
    width: 160,
    headerName: '결제일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<Billing>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
  {
    field: 'createdAt',
    minWidth: 160,
    width: 160,
    headerName: '등록일',
    sortable: false,
    cellRenderer: ({ value }: ICellRendererParams<Billing>) => {
      return value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '-';
    },
  },
];
