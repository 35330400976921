import React, { useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build/build/ckeditor';
import { EditorUploadAdapterPlugin } from './ImageUploader';

export interface EditorProps {
  data?: string;
  onChange?: (data: any) => void;
  placeholder?: string;
  uploadFolder?: string;
}

function HtmlEditor(props: EditorProps) {
  const {
    data: initialHtmlData,
    onChange,
    uploadFolder,
    placeholder = '내용을 입력해주세요',
  } = props;
  const editorRef = useRef(null);

  const editorConfig = {
    uploadFolder,
    extraPlugins: [EditorUploadAdapterPlugin],
    alignment: {
      options: ['left', 'right'],
    },
    fontSize: {
      options: [9, 11, 13, 16, 17, 19, 21],
    },
    toolbar: {
      items: [
        'heading',
        'fontSize',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'alignment',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
        '|',
        'link',
        'imageUpload',
        'insertTable',
        'undo',
        'redo',
      ],
      shouldNotGroupWhenFull: true,
    },
    placeholder,
  };

  return (
    <CKEditor
      ref={editorRef}
      editor={ClassicEditor}
      config={editorConfig}
      data={initialHtmlData}
      onChange={(event: any, editor: { getData: () => any }) => {
        const data = editor.getData();
        if (onChange) onChange(data);
      }}
    />
  );
}

HtmlEditor.defaultProps = {
  data: '',
};

export default HtmlEditor;
