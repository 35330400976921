import { AppError, request, Row, Rows } from '@networks/index';
import { FetchBillingPlanListQuery } from '../@types/billing-plan/params/FetchBillingPlanListQuery';
import { Billing } from '../@types/billing/models/Billing';
import { CreateBillingBody } from '../@types/billing/params/CreateBillingBody';
import { UpdateBillingBody } from '../@types/billing/params/UpdeteBillingBody';

const API_ROOT = 'admin/billings';

export const createBilling = async (body: CreateBillingBody) => {
  try {
    const { data } = await request<Row<Billing>>({
      method: 'post',
      url: API_ROOT,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const updateBilling = async (id: number, body: UpdateBillingBody) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<Billing>>({
      method: 'put',
      url,
      requestBody: body,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const fetchBillings = async (query: FetchBillingPlanListQuery) => {
  try {
    const { data } = await request<Rows<Billing>>({
      method: 'get',
      url: API_ROOT,
      queryParams: query,
    });
    return data;
  } catch (e) {
    return {
      rows: [],
      count: 0,
    };
  }
};

export const fetchBillingDetail = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    const { data } = await request<Row<Billing>>({
      method: 'get',
      url,
    });
    return data;
  } catch (e) {
    throw new AppError(e);
  }
};

export const deleteBilling = async (id: number | string) => {
  const url = `${API_ROOT}/${id}`;
  try {
    await request({
      method: 'delete',
      url,
    });
  } catch (e) {
    throw new AppError(e);
  }
};
